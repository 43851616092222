import { render, staticRenderFns } from "./PanelJurisdiccion.vue?vue&type=template&id=4add725c&scoped=true&"
import script from "./PanelJurisdiccion.vue?vue&type=script&lang=js&"
export * from "./PanelJurisdiccion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4add725c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VScrollYReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VCardText,VDivider,VIcon,VScrollYReverseTransition})
