<template>
  <v-dialog max-width="500" v-model="ver">
    <v-card>
      <v-card-title class="d-flex justify-center">Confirmar datos de escritura y extracto</v-card-title>
      <v-card-text>
        <v-alert color="info" border="left" outlined dense>
          <p class="text-center mb-0" style="font-size: 17px">
            Confirmo que los datos de notarios y códigos electrónicos corresponden a la <span
              class="font-weight-bold">escritura y extracto de la misma. </span>Proporcionar erróneamente los datos solicitados puede generar reparos y retrasar
            el avance de su trámite.
          </p>
        </v-alert>

        <div class="text-center">
          <v-btn outlined color="grey" block @click="hideDialog">
            <v-icon left>mdi-close</v-icon>
            No, volver al paso anterior
          </v-btn>
          <v-btn class="mt-3" outlined color="info" block @click="confirmar">
            <v-icon left>mdi-check</v-icon>
            Sí, pertenecen a la<span class="font-weight-bold pl-1">escritura y extracto</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalConfirmacionExtracto",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    montoCotizado: {default: null}
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    confirmar() {
      this.$emit("confirmar");
      this.hideDialog()
    }

  }
}
</script>

<style scoped>

</style>
