<template>
  <v-scroll-y-reverse-transition mode="out-in">
    <v-card
        key="seleccion"
        v-if="!jurisdiccionSeleccionada"
        class="mb-6"
    >
      <v-card-text>
        <h2 class="font-weight-regular mb-3">{{ titulo_jurisdiccion }}</h2>
        <v-divider></v-divider>
        <div class="d-flex px-0" style="font-size: 15px !important; cursor: pointer"
             @click="seleccionarJurisdiccion()">
          <v-icon style="font-size: inherit" class="pr-2" color="success">mdi-checkbox-blank-outline</v-icon>
          <p class="my-3 font-weight-medium pl-1" style="justify-content: left !important; ">
            <!--                <span> La propiedad se encuentra ubicada en la comuna de La Serena o La Higuera</span>-->
            <span> {{ jurisdiccion_ok }}</span>
          </p>
        </div>
        <div class="d-flex px-0" style="font-size: 15px; cursor: pointer" @click="dialog_jurisdiccion = true">
          <v-icon color="info" small class="pr-2" style="font-size: inherit">mdi-checkbox-blank-outline</v-icon>
          <p class="my-0 font-weight-regular pl-1" style="justify-content: left !important;">
            <!--               <span> La propiedad se encuentra en otra comuna</span>-->
            <span>{{ otra_jurisdiccion }}</span>
          </p>
        </div>


      </v-card-text>
    </v-card>

    <v-card
        key="confirmado"
        v-else
        class="mb-6"
    >
      <v-card-text class="py-0">
        <div class="d-flex px-4" style="font-size: 15px !important; cursor: pointer">
          <v-icon style="font-size: inherit" class="pr-2" color="success">mdi-check</v-icon>
          <p class="my-3 success--text pl-1" style="justify-content: left !important; ">

            <!--                <span> La propiedad se encuentra ubicada en la comuna de La Serena o La Higuera</span>-->
            <span> {{ jurisdiccion_ok }}</span>
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-scroll-y-reverse-transition>

</template>

<script>
export default {
  name: "PanelJurisdiccion",
  props: {
    jurisdiccionSeleccionada: {
      default: false
    },
    titulo_jurisdiccion: {
      default: "Seleccione donde se encuentra ubicada la propiedad."
    },
    jurisdiccion_ok:{
      default: "La propiedad se encuentra ubicada en la comuna de La Serena o La Higuera."
    },
    otra_jurisdiccion: {
      default: "La propiedad se encuentra en otra comuna."
    }
  },
  data(){
    return {
      dialog_jurisdiccion: false
    }
  },
  methods: {
    seleccionarJurisdiccion() {
      setTimeout(() => {
        this.$emit("confirmarJurisdiccion")
      }, 200)
    },
  }
}
</script>

<style scoped>

</style>
