<template>
    <div>
        <p class="mb-0 pt-1"><span class="font-weight-bold">PASO 1:</span> ingrese los datos correspondientes a la <span class="font-weight-bold">escritura</span>
        </p>
        <v-form
                @submit.prevent="verificarDocumento(formDocumento.notarioSeleccionado, formDocumento.idDocumento)"
                autocomplete="off"
        >
            <v-row>
                <v-col
                        class="pb-0"
                >
                    <div
                            class="d-flex flex-column flex-sm-row align-stretch align-sm-baseline"
                    >
                        <v-autocomplete
                                v-model="formDocumento.notarioSeleccionado"
                                :items="listaNotarios"
                                :loading="cargandoListaNotarios ? 'secondary' : false"
                                :search-input.sync="busquedaNotario"
                                hide-no-data
                                :disabled="verificandoDocumento || documentoVerificado || !camposHabilitados"
                                label="Notario titular"
                                required
                                :autofocus="!$vuetify.breakpoint.mobile"
                                @input="$v.formDocumento.notarioSeleccionado.$touch()"
                                @blur="$v.formDocumento.notarioSeleccionado.$touch()"
                                :error-messages="notarioSeleccionadoErrors"
                                id="inputTitular"
                        ></v-autocomplete>
                        <v-text-field
                                class="ml-0 ml-sm-6"
                                v-model="formDocumento.idDocumento"
                                label="Código de verificación"
                                :disabled="verificandoDocumento || documentoVerificado || !camposHabilitados"
                                required
                                @input="$v.formDocumento.idDocumento.$touch()"
                                @blur="$v.formDocumento.idDocumento.$touch()"
                                :error-messages="idDocumentoErrors"
                        ></v-text-field>
                        <v-btn
                                class="ml-0 ml-sm-3 mt-3 mb-3 mt-sm-0"
                                type="submit"
                                color="primary"
                                small
                                :disabled="!camposHabilitados"
                                :loading="verificandoDocumento"
                        >
                            <v-icon class="mr-2" small>mdi-text-box-search-outline</v-icon>
                            Verificar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>


        </v-form>
        <v-fade-transition>
            <v-row class="my-0" v-if="verificacionDocumento != null && !verificandoDocumento">
                <v-col class="py-0">
                    <v-alert
                            class="mb-3"
                            outlined
                            border="left"
                            :type="verificacionDocumento.documentoVerificado ? 'success' : 'error' "
                    >
                        <p v-if="verificacionDocumento.documentoVerificado" class="mb-0">
                            La <span class="font-weight-bold">escritura</span> ha sido cargada correctamente.
                            Presione <a class="text--primary" target="_blank"
                                        :href="verificacionDocumento.mensaje">aquí</a> para visualizar
                            su <span class="font-weight-bold">escritura</span>.
                        </p>
                        <p v-else class="mb-0">
                            {{ verificacionDocumento.mensaje }}
                        </p>


                    </v-alert>
                    <div v-if="Paso2listo">
                        <!--            <v-divider class="my-4"></v-divider>-->
                        <!--            <p class="mb-1 text-center">Revise que la escritura cargada corresponde con lo solicitado</p>-->

                        <v-row
                                class="d-none d-md-flex"
                                v-if="verificacionDocumento.documentoVerificado"
                        >
                            <v-col cols="12" class="text-center">
                                <v-btn class="mr-2" color="warning" small outlined @click="limpiarTodo">
                                    <v-icon left small>mdi-close</v-icon>
                                    No es la escritura
                                </v-btn>
                                <v-btn color="success" class="ml-2" small @click="agregarExtractoDeLaEscritura"
                                       :disabled="documentoVerificado || !camposHabilitados">
                                    <v-icon left small>mdi-check</v-icon>
                                    La escritura es la correcta
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row
                            class="d-md-none d-block"
                            v-if="verificacionDocumento.documentoVerificado"
                        >
                            <v-col cols="12" class="text-center">
                                <v-btn block class="mr-1" color="warning" small outlined @click="limpiarTodo">
                                    <v-icon left small>mdi-close</v-icon>
                                    No es la escritura
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <v-btn block color="success" small @click="agregarExtractoDeLaEscritura"
                                       :disabled="documentoVerificado || !camposHabilitados">
                                    <v-icon left small>mdi-check</v-icon>
                                    {{textoBtnConfirmar}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-fade-transition>

        <!--     ---------------------------------------------aquí agrego claudio-------------------------------------------------------------   -->
        <v-fade-transition>
            <div v-if="mostrarExtracto">
                <v-divider class="my-3"></v-divider>
                <p class="mb-0 pt-1"><span class="font-weight-bold">PASO 2:</span> ingrese los datos correspondientes al <span class="font-weight-bold">extracto de la escritura</span>
                </p>
                <v-form
                        @submit.prevent="verificarDocumentoExtracto(formDocumentoExtracto.notarioSeleccionado, formDocumentoExtracto.idDocumento)"
                        autocomplete="off"
                >
                    <v-row>
                        <v-col
                                class="pb-0"
                        >
                            <div
                                    class="d-flex flex-column flex-sm-row align-stretch align-sm-baseline"
                            >
                                <v-autocomplete
                                    :disabled="verificandoDocumento || documentoVerificado || !camposHabilitados"
                                        v-model="formDocumentoExtracto.notarioSeleccionado"
                                        :items="listaNotarios"
                                        hide-no-data
                                        label="Notario titular"
                                        required
                                        :autofocus="!$vuetify.breakpoint.mobile"
                                        @input="$v.formDocumentoExtracto.notarioSeleccionado.$touch()"
                                        @blur="$v.formDocumentoExtracto.notarioSeleccionado.$touch()"
                                        :error-messages="notarioSeleccionadoErrorsExtracto"
                                        id="inputTitularExtracto"
                                ></v-autocomplete>
                                <v-text-field
                                    :disabled="verificandoDocumento || documentoVerificado || !camposHabilitados"
                                        class="ml-0 ml-sm-6"
                                        v-model="formDocumentoExtracto.idDocumento"
                                        label="Código de verificación"
                                        required
                                        @input="$v.formDocumentoExtracto.idDocumento.$touch()"
                                        @blur="$v.formDocumentoExtracto.idDocumento.$touch()"
                                        :error-messages="idDocumentoErrorsExtracto"
                                ></v-text-field>
                                <v-btn
                                        class="ml-0 ml-sm-3 mt-3 mb-3 mt-sm-0"
                                        type="submit"
                                        color="primary"
                                        small
                                        :disabled="!camposHabilitados"
                                        :loading="verificandoDocumentoExtracto"
                                >
                                    <v-icon class="mr-2" small>mdi-text-box-search-outline</v-icon>
                                    Verificar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                    <v-alert
                        :value="CodigosDuplicado"
                        class="mt-2"
                        color="red"
                        dense
                        outlined
                        type="info"
                    >El código del extracto no puede ser idéntico al código de la escritura</v-alert>

                </v-form>
                <!--                -->
                <v-fade-transition>
                    <v-row class="my-0" v-if="verificacionDocumentoExtracto != null && !verificandoDocumentoExtracto">
                        <v-col class="py-0">
                            <v-alert
                                class="mb-3"
                                outlined
                                border="left"
                                color="info"
                                :type="verificacionDocumentoExtracto.documentoVerificado ? 'success' : 'error' "
                            >
                                <p v-if="verificacionDocumentoExtracto.documentoVerificado" class="mb-0">
                                    El <span class="font-weight-bold">extracto</span> ha sido cargado correctamente.
                                    Presione <a class="text--primary" target="_blank"
                                                :href="verificacionDocumentoExtracto.mensaje">aquí</a> para visualizar
                                    el <span class="font-weight-bold">extracto</span>.
                                </p>
                                <p v-else class="mb-0">
                                    {{ verificacionDocumentoExtracto.mensaje }}
                                </p>


                            </v-alert>
                            <div v-if="pasosListo">
                                <!--            <v-divider class="my-4"></v-divider>-->
                                <!--            <p class="mb-1 text-center">Revise que la escritura cargada corresponde con lo solicitado</p>-->

                                <v-row
                                    class="d-none d-md-flex"
                                    v-if="verificacionDocumentoExtracto.documentoVerificado"
                                >
                                    <v-col cols="12" class="text-center">
                                        <v-btn class="mr-2" color="warning" small outlined @click="limpiarTodo">
                                            <v-icon left small>mdi-close</v-icon>
                                            No es el extracto
                                        </v-btn>
                                        <v-btn color="success" class="ml-2" small @click="confirmarDocumento"
                                               :disabled="documentoVerificado || !camposHabilitados">
                                            <v-icon left small>mdi-check</v-icon>
                                            El extracto es el correcto
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row
                                    class="d-md-none d-block"
                                    v-if="verificacionDocumentoExtracto.documentoVerificado"
                                >
                                    <v-col cols="12" class="text-center">
                                        <v-btn block class="mr-1" color="warning" small outlined @click="limpiarTodo">
                                            <v-icon left small>mdi-close</v-icon>
                                            No es la escritura
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" class="text-center">
                                        <v-btn block color="success" small @click="confirmarDocumento"
                                               :disabled="documentoVerificado || !camposHabilitados">
                                            <v-icon left small>mdi-check</v-icon>
                                            {{textoBtnConfirmar}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-fade-transition>
                <!--                -->
            </div>
        </v-fade-transition>
    </div>
</template>

<script>
import notario from "@/model/notario/notario.entity";
import {required} from "vuelidate/lib/validators";

export default {
    name: "VerificacionEscrituraYExtractoNotarios",

    components: {},
    props: {
        textoBtnConfirmar: {
            default: "aceptar"
        },
        documentoVerificado: {
            default: false
        },
        camposHabilitados: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            //form validación documento
            formDocumento: {
                //inputs
                notarioSeleccionado: null,
                idDocumento: null,
            },
            formDocumentoExtracto: {
                //inputs
                notarioSeleccionado: null,
                idDocumento: null,
            },
            //autocomplete
            cargandoListaNotarios: false,
            listaNotarios: [],
            busquedaNotario: null,
            //verificación documento
            verificandoDocumento: false,
            verificacionDocumento: null,
            mostrarExtracto: false,
            verificandoDocumentoExtracto: false,
            verificacionDocumentoExtracto: null,
            Paso2listo:true,
            pasosListo:true,
            CodigosDuplicado: false,
        };
    },
    validations: {
        formDocumento: {
            notarioSeleccionado: {required},
            idDocumento: {required},
        },
        formRepertorioPartes: {
            repertorio: {required},
            partes: {required}
        },
        formDocumentoExtracto: {
            notarioSeleccionado: {required},
            idDocumento: {required},
        },
        formRepertorioPartesExtracto: {
            repertorio: {required},
            partes: {required}
        },
    },
    created() {
        //los notarios ya se cargaron


        if (this.listaNotarios.length > 0) {
            return;
        }

        //los notarios ya se están pidiendo
        if (this.cargandoListaNotarios) {
            return;
        }

        this.cargandoListaNotarios = true;
        notario
            .getLista()
            .then((response) => {
                this.listaNotarios = response;
            })
            .catch(() => {
            })
            .finally(() => {
                this.cargandoListaNotarios = false;
            });

    },
    watch: {
        busquedaNotario() {

        },
    },
    methods: {
        agregarExtractoDeLaEscritura() {
            this.Paso2listo = false;
            this.mostrarExtracto = true;
        },
        confirmarDocumento() {
            this.$emit("confirmarDocumento", this.formDocumento);
            this.$emit("confirmarDocumentoExtracto", this.formDocumentoExtracto);
            this.pasosListo = false;
        },
        limpiarTodo() {
            this.$emit("limpiarTodo");
        },
        verificarDocumento(notarioSeleccionado, idDocumento) {

            this.$v.formDocumento.$touch();

            if (this.$v.formDocumento.$invalid) {
                return;
            }

            this.verificandoDocumento = true;
            notario
                .verificarDocumento(notarioSeleccionado.id, idDocumento)
                .then((response) => {
                    this.verificacionDocumento = response;
                    if (response.documentoVerificado) {
                        // this.$emit("e-documento-validado", this.formDocumento);
                    }

                })
                .catch((error) => {
                    this.verificacionDocumento = {
                        documentoVerificado: false,
                        mensaje: error.mensaje,
                    }
                    console.error(error);
                })
                .finally(() => {
                    this.verificandoDocumento = false;
                });
        },
        verificarDocumentoExtracto(notarioSeleccionado, idDocumento) {

            if(this.formDocumentoExtracto.idDocumento === this.formDocumento.idDocumento){
                this.CodigosDuplicado = true
                this.formDocumentoExtracto = {
                    //inputs
                    notarioSeleccionado: null,
                    idDocumento: null,
                };
                setTimeout(() => {
                    this.CodigosDuplicado = false
                }, 7000)
            }else {
                this.$v.formDocumentoExtracto.$touch();

                if (this.$v.formDocumentoExtracto.$invalid) {
                    return;
                }

                this.verificandoDocumentoExtracto = true;
                notario
                    .verificarDocumento(notarioSeleccionado.id, idDocumento)
                    .then((response) => {
                        this.verificacionDocumentoExtracto = response;
                        if (response.documentoVerificado) {
                            // this.$emit("e-documento-validado", this.formDocumento);
                        }

                    })
                    .catch((error) => {
                        this.verificacionDocumentoExtracto = {
                            documentoVerificado: false,
                            mensaje: error.mensaje,
                        }
                        console.error(error);
                    })
                    .finally(() => {
                        this.verificandoDocumentoExtracto = false;
                    });
            }
        },

        refLimpiarDatos() {
            this.formDocumento = {
                //inputs
                notarioSeleccionado: null,
                idDocumento: null,
            };
            this.formDocumentoExtracto = {
                //inputs
                notarioSeleccionado: null,
                idDocumento: null,
            };
            this.verificacionDocumento = null;
            this.verificacionDocumentoExtracto = null
            setTimeout(() => {
                document.getElementById("inputTitular").focus();
            }, 100)

        }
    },
    computed: {
        notarioSeleccionadoErrors: function () {
            const errors = [];
            if (!this.$v.formDocumento.notarioSeleccionado.$dirty) return errors;
            !this.$v.formDocumento.notarioSeleccionado.required && errors.push("Escriba y seleccione el nombre de un notario");
            return errors;
        },
        idDocumentoErrors: function () {
            const errors = [];
            if (!this.$v.formDocumento.idDocumento.$dirty) return errors;
            !this.$v.formDocumento.idDocumento.required && errors.push("Ingrese el código de verificación de su documento");
            return errors;
        },
        notarioSeleccionadoErrorsExtracto: function () {
            const errors = [];
            if (!this.$v.formDocumentoExtracto.notarioSeleccionado.$dirty) return errors;
            !this.$v.formDocumentoExtracto.notarioSeleccionado.required && errors.push("Escriba y seleccione el nombre de un notario");
            return errors;
        },
        idDocumentoErrorsExtracto: function () {
            const errors = [];
            if (!this.$v.formDocumentoExtracto.idDocumento.$dirty) return errors;
            !this.$v.formDocumentoExtracto.idDocumento.required && errors.push("Ingrese el código de verificación de su documento");
            return errors;
        },
    },
};
</script>
