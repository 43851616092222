import getPrecioCalculadoDocumento from "@/model/inscripciones/usecases/get-precio-calculado-documento";

export  const DIVISA = {
    NA: 0,
    CLP: 1,
    UF: 2
}
const inscripcionesEntity = {
    getPrecioCalculadoDocumento
}

export default inscripcionesEntity;
