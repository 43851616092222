<template>
  <div>
    <v-text-field v-model="cve"
                  hide-details
                  required
                  label="CVE"></v-text-field>

    <div class="d-none d-sm-block text-right mt-3">

      <v-btn :disabled="cve == null || cargandoArchivo || cve === ''" type="button" color="info" small class="px-10"
             @click="validarPDF" :loading="cargandoArchivo">
        <v-icon small left>mdi-file-check-outline</v-icon>
        Validar Publicación
      </v-btn>
    </div>
    <div class="d-block d-sm-none mt-5 d-flex justify-space-between">
      <v-btn block :disabled="archivoSeleccionado == null || cargandoArchivo " type="button" color="info" small class="px-10"
             @click="validarPDF" :loading="cargandoArchivo">
        <v-icon small left>mdi-file-check-outline</v-icon>
        Validar Publicación
      </v-btn>
    </div>

    <v-fade-transition>
      <div class="pt-2 pt-sm-0" v-if="documentoValidado">
        <div v-if="linkToDiarioOficial != null">
          <v-alert
              class="text-center mt-3"
              outlined dense color="info"
          >
            Documento verificado, puede comprobar la publicación <a target="_blank" :href="linkToDiarioOficial">presionando
            aquí.</a>
          </v-alert>
        </div>

        <div v-if="linkToDiarioOficial == null">
          <v-alert class="text-center mt-3" outlined dense color="error">
            Lo sentimos, la publicación del extracto no pudo ser validada, por favor verifique la disponibilidad del documento en el portal del Diario Oficial
          </v-alert>
        </div>
      </div>
    </v-fade-transition>

  </div>

</template>

<script>

import fileEntity from "@/model/file/file.entity";

export default {
  name: "FormularioCargaPDFEscritura",
  data() {
    return {
      cve: null,
      archivoSeleccionado: null,
      documentoValidado: false,
      linkToDiarioOficial: null,
      cargandoArchivo: false,
      nombre: null
    }
  },
  methods: {
    handleFileUpload(event) {
      console.log("llama a esta funcion?")
      if (event == undefined) {
        console.log("se modificaron los datos a null")
        this.linkToDiarioOficial = null;
        this.documentoValidado = false;
        this.$emit("validado", null);
      }
      console.log("archivo: ", this.archivoSeleccionado)
    },
    validarPDF() {
      this.cargandoArchivo = true;
      fileEntity.validarPdfEscrituraDiarioOficial(this.cve).then(res => {
        this.cargandoArchivo = false;
        this.documentoValidado = true;
        if (res.status) {
          this.linkToDiarioOficial = res.obj.url;
          this.nombre = res.obj.nombre;
          this.$emit("validado", res.obj)
        } else {
          this.linkToDiarioOficial = null;
          this.$emit("validado", null);
        }
        console.log("validado", res.obj);
      });
    }
  }
}
</script>

<style scoped>

</style>
