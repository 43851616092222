import axios from "axios";
import qs from "qs";

export const validarPdfEscrituraDiarioOficialApi = (cve) => {

    return new Promise((resolve, reject) => {
        axios.post(
            `/files?action=validar-pdf-inscripcion-comercio-diario-oficial-cve`,
            qs.stringify({
                cve: cve
                }
            )
        ).then(res => {
            resolve(res.data);
        }).catch(error => {
            reject(error);
        })
    });
}