<template>
    <div>
        <v-row>

            <v-col cols="12" md="8" class="pr-md-0">
                <v-card class="px-4">
                    <v-card-title class="pb-0">
                        {{ title }}
                    </v-card-title>
                    <v-card-text v-if="dataFormulario == null">
                        <p class="mb-0">Ingrese los datos correspondientes a la escritura</p>
                        <verificacion-escritura-notarios
                                ref="refVerificacionEscritura"
                                @limpiarTodo="limpiarTodo"
                                :campos-habilitados="!documento_confirmado"
                                @confirmarDocumento="documentoValidado($event)"></verificacion-escritura-notarios>
                    </v-card-text>
                    <v-card-text v-if="dataFormulario != null">

                        <v-row>

                            <v-col cols="6" class="pb-0" :key="dato.nombre" v-for="dato in dataFormulario">
                                <small class="font-weight-bold">{{ dato.nombre }}</small>
                                <v-alert
                                        border="left"
                                        color="black"
                                        dense
                                        :icon="false"
                                        outlined
                                        class="text-center py-1"
                                >
                                    <span style="color:black; font-size: 13px">
                                          {{ dato.valor }}
                                    </span>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-fade-transition>
                    <div v-if="documento_confirmado && dataFormulario == null">
                        <v-card class="mt-3 px-4">
                            <agregar-f2890
                                @cambiarEstadoDelSelect="estadoDelSelect = $event"
                                @numeroF2890="numeroF2890 = $event"
                            ></agregar-f2890>
                        </v-card>
                        <v-card class="mt-3 px-4 pb-3">
                            <v-card-title class="pb-0">Requerimientos</v-card-title>
                            <v-card-text>
                                <lista-certificados-inscripciones :listaPorAgregar="lista_por_agregar"
                                                                  :desactivar-select-props="estadoDelSelect"
                                                                  @agregarCertificado="agregarALista"
                                                                  @actualizarGP="actualizarGP"
                                                                  @actualizarLitigio="actualizarLitigio"
                                                                  @guardarCertificados="guardarCertificados"

                                ></lista-certificados-inscripciones>
                            </v-card-text>
                        </v-card>

                        <inscripciones-view-lista-carro
                                :numero-f2890="numeroF2890"
                                :gp="gp"
                                :litigio="litigio"
                                :observacionCargada="observacionCargada" ref="carrito"
                                :lista="lista_por_agregar" :datos="informacion_documento"
                                @agregarCertificado="agregarALista"
                                @eliminarElemento="eliminarDeLista($event)"></inscripciones-view-lista-carro>
                    </div>

                </v-fade-transition>

                <v-fade-transition>
                    <div v-if="dataFormulario != null" class="pt-3">
                        <v-alert
                                text
                                type="info"
                                rounded class="text-center"
                        >
                            Si desea agregar o modificar las inscripciones, por favor, vacíe su carro de compras

                        </v-alert>
                    </div>
                </v-fade-transition>

            </v-col>
            <v-col sm="12" md="4" class=" pl-sm-6">
                <InformacionDocumento :certificado="certificado" v-if="!load.certificado"></InformacionDocumento>
            </v-col>
        </v-row>


    </div>
</template>

<script>
import registros, {
  ID_AREA_AGRUPACION, ID_TIPO_CERTIFICADO,
  ID_TIPO_REGISTRO
} from "@/model/registros/registros.entity";
import VerificacionEscrituraNotarios
    from "@/components/SolicitudDocumentos/Formularios/AlzamientoCancelacion/VerificacionEscrituraNotarios";
import InformacionDocumento from "@/components/SolicitudDocumentos/InformacionDocumento";
import ListaCertificadosInscripciones
    from "@/views/index/inscripciones/propiedad/inscripcion-propiedad/ListaCertificadosInscripciones";
import InscripcionesViewListaCarro
    from "@/views/index/inscripciones/propiedad/inscripcion-propiedad/InscripcionesViewListaCarro";
import carro from "@/model/carro/carro.entity";
import AgregarF2890 from "@/views/index/inscripciones/propiedad/inscripcion-propiedad/AgregarF2890.vue";

export default {
    name: "AgregarInscripcionesView",
    components: {
        AgregarF2890,
        InscripcionesViewListaCarro,
        ListaCertificadosInscripciones, InformacionDocumento, VerificacionEscrituraNotarios
    },
    data() {
        return {
            tipos_certificados: [],
            certificado: {}, //generar un certificado por defecto solo para la vista previa
            load: {
                tipos_certificados: false,
                certificado: false
            },
            documento_validado: false,
            documento_confirmado: false,
            title: "",
            lista_por_agregar: [],
            informacion_documento: {},
            dataFormulario: null,
            observacionCargada: null,
            gp: {},
            litigio: {},
            estadoDelSelect: Boolean,
            numeroF2890: Number,
        }
    },
    created() {
        registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.INSCRIPCION_PROPIEDAD, ID_TIPO_CERTIFICADO.INCRIPCION_PROPIEDAD_CON_COPIA_VIGENCIA).then(respuesta => {
          this.certificado = respuesta;
        });

        this.dataFormulario = carro.getDatosAdicionalesCertificado(ID_TIPO_REGISTRO.INSCRIPCION_PROPIEDAD, ID_AREA_AGRUPACION.INSCRIPCION_PROPIEDAD);
        if (this.dataFormulario) {
            let obs = this.dataFormulario.find(f => {
                return f.nombre == "Observaciones"
            })

            if (obs) {
                this.observacionCargada = obs.valor;
                this.dataFormulario.splice(this.dataFormulario.indexOf(obs), 1);
            }
            this.documento_validado = true
            this.informacion_documento.notarioSeleccionado = {};
            console.log("datos adicionales que tengo: ", this.dataFormulario)
            this.informacion_documento.notarioSeleccionado.nombre = this.dataFormulario.find(f => {
                return f.nombre.toLowerCase() == "notario";
            }).valor

            this.informacion_documento.idDocumento = this.dataFormulario.find(f => {
                return f.nombre.toLowerCase() == "id_documento_electronico";
            }).valor

            this.confirmarDocumento();
        }

        this.title = "Inscripción de propiedad";

    },
    methods: {
        guardarCertificados(gp, litigio) {
            console.log('gp =', gp, 'litigio', litigio)
            let certificado = {
                id_tipo_registro_FK: gp.id_tipo_registro_FK,
                id_tipo_certificado: gp.id_tipo_certificado,
                precio_pagado: gp.precio,
                precio_cliente: gp.precio,
                tipo_certificado: gp.tipo_certificado,
                tipo_registro: gp.tipo_registro,
                id_divisa: 1,
                numero_copias: 0,
                id_area_agrupacion: gp.id_area_agrupacion
            };

            this.gp = certificado;

            let litigio_ = {
                id_tipo_registro_FK: litigio.id_tipo_registro_FK,
                id_tipo_certificado: litigio.id_tipo_certificado,
                precio_pagado: litigio.precio,
                precio_cliente: litigio.precio,
                tipo_certificado: litigio.tipo_certificado,
                tipo_registro: litigio.tipo_registro,
                id_divisa: 1,
                numero_copias: 0,
                id_area_agrupacion: litigio.id_area_agrupacion
            }
            this.litigio = litigio_;
        },

        documentoValidado(documento) {
            this.informacion_documento = documento;
            this.documento_validado = true;
            this.confirmarDocumento()
        },
        confirmarDocumento() {
            this.documento_confirmado = true;
        },
        agregarALista(certificado, certificado2) {

            if (certificado != null) {
                this.validarCertificado(certificado);
            }

            if (certificado2 != null) {
                this.validarCertificado(certificado2);
            }
        },

        validarCertificado(certificado) {
            let aux = this.lista_por_agregar.find(c => {
                return c.id_tipo_certificado === certificado.id_tipo_certificado && c.id_tipo_registro_FK === certificado.id_tipo_registro_FK && c.precio_pagado === certificado.precio_pagado
            })
            if (aux != null) {
                aux.numero_copias = parseInt(aux.numero_copias) + parseInt(certificado.numero_copias);
            } else {
                this.lista_por_agregar.push(certificado);
            }

        },

        eliminarDeLista(cert) {
            this.lista_por_agregar.splice(this.lista_por_agregar.indexOf(cert), 1);
        },
        limpiarTodo() {
            this.documento_validado = false;
            this.documento_confirmado = false;
            this.lista_por_agregar = [];
            this.informacion_documento = {};
            this.$refs.refVerificacionEscritura.refLimpiarDatos();
        },
        actualizarGP(incluir) {
            this.$refs.carrito.actualizarGP(incluir);
        },
        actualizarLitigio(incluir) {
            this.$refs.carrito.actualizarLitigio(incluir);
        }


    }
}
</script>

<style scoped>

</style>
