<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" class="pr-md-0">

        <panel-jurisdiccion :jurisdiccionSeleccionada="jurisdiccionSeleccionada"
                            @confirmarJurisdiccion="confirmarJurisdiccion"></panel-jurisdiccion>
        <v-fade-transition>
          <div>
            <v-card class="px-4 py-2">
              <h2 class=" px-4 pt-2 font-weight-regular">
                Alzamiento de Prohibición SERVIU por Plazo Vencido
              </h2>
              <div v-if="!dataFormulario">
                  <v-col class="px-4">
                    <h4 style="font-size: 16px;font-weight: normal" class="pb-2 pt-2">
                      Ingrese foja, número y año del título que posee la Prohibición SERVIU
                    </h4>
                    <div>
                      <f-n-a :campos-habilitados="jurisdiccionSeleccionada "
                             @setFormulario="setFormulario2($event)"
                             @send="comprobarSiPuedeComprar()"
                             :item="{numero: -1}"></f-n-a>
                    </div>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn
                        small
                        color="primary"
                        @click="comprobarSiPuedeComprar()"
                        :disabled="!jurisdiccionSeleccionada"
                        :loading="loadingBtnComprobar"
                    >COMPROBAR REQUERIMIENTO
                    </v-btn>
                  </v-col>
                <div v-if="indice">
                  <h4 class=" px-4 pt-2 font-weight-regular">
                    Se a encontrado la siguiente Prohibición SERVIU
                  </h4>
                  <v-col cols="12">
                    <f-n-a
                        :campos-habilitados="false"
                           @setFormulario="setFormulario($event)"
                           :item="indice"></f-n-a>
                  </v-col>
                </div>
                <v-col cols="12">
                  <h3 class="pb-2 pt-2">Agregar documentos opcionales a la solicitud</h3>
                  <v-checkbox
                      :disabled="!jurisdiccionSeleccionada || prohibicionSERVIU"
                      dense
                      v-model="has_gp"
                      label="Certificado de Hipotecas y Prohibiciones del título"
                      hide-details
                      class="ma-0"
                  ></v-checkbox>
                  <v-checkbox
                      :disabled="!jurisdiccionSeleccionada || prohibicionSERVIU"
                      dense
                      v-model="tiene_copiaAutorizadaProhibicion"
                      label="Copia autorizada de la Prohibición"
                      hide-details
                      class=" ma-0"
                  ></v-checkbox>
                  <v-checkbox
                      :disabled="!jurisdiccionSeleccionada || prohibicionSERVIU"
                      dense
                      hide-details
                      class="ma-0"
                      v-model="tiene_copiaConVigenciaODominoVigente"
                      label="Copia con Vigencia o Dominio Vigente del título"
                  ></v-checkbox>
                  <v-checkbox
                      :disabled="!jurisdiccionSeleccionada || prohibicionSERVIU"
                      dense
                      v-model="tiene_copiaAutorizada_sinVigencia"
                      label="Copia Autorizada del título"
                      class=" ma-0"
                  ></v-checkbox>

                  <div class="text-center">
                    <v-btn
                        @click="enviarAlCarro()" color="info"
                        small
                        outlined
                        :disabled="!jurisdiccionSeleccionada || prohibicionSERVIU"
                    >
                      <v-icon left small>mdi-cart-plus</v-icon>
                      Agregar trámites al carro
                    </v-btn>
                  </div>
                </v-col>
              </div>
              <!-- --------------------------------------------se cambia la vista si tiene un alzamiento de prohibición en el carro------------------------------------- -->
              <v-card-text class="pt-0 mt-0">
                <div v-if="dataFormulario">
                  <h4 style="font-size: 16px;font-weight: normal" class="px-4 pt-2 font-weight-regular">
                    Ingrese foja, número y año del título
                  </h4>
                  <div class="d-flex">
                    <v-col cols="4" class="pb-0">
                      <small class="font-weight-bold">Foja</small>
                      <v-alert
                          border="left"
                          color="black"
                          dense
                          :icon="false"
                          outlined
                          class="text-center py-1"
                      >
                        <div style="color:black; font-size: 13px">
                          {{ fojaTitulo }}
                        </div>
                      </v-alert>
                    </v-col>
                    <v-col cols="4" class="pb-0">
                      <small class="font-weight-bold">Número</small>
                      <v-alert
                          border="left"
                          color="black"
                          dense
                          :icon="false"
                          outlined
                          class="text-center py-1"
                      >
                        <div style="color:black; font-size: 13px">
                          {{ numeroTitulo }}
                        </div>
                      </v-alert>
                    </v-col>
                    <v-col cols="4" class="pb-0">
                      <small class="font-weight-bold">Año</small>
                      <v-alert
                          border="left"
                          color="black"
                          dense
                          :icon="false"
                          outlined
                          class="text-center py-1"
                      >
                        <div style="color:black; font-size: 13px">
                          {{ anhoTitulo }}
                        </div>
                      </v-alert>
                    </v-col>
                  </div>
                  <h4 class=" px-4 pt-2 font-weight-regular">
                    Ingrese foja, número y año de la Prohibición para la cual requiere su alzamiento
                  </h4>
                  <div class="d-flex">
                    <v-col cols="4" class="pb-0">
                      <small class="font-weight-bold">Foja</small>
                      <v-alert
                          border="left"
                          color="black"
                          dense
                          :icon="false"
                          outlined
                          class="text-center py-1"
                      >
                        <div style="color:black; font-size: 13px">
                          {{ indice.foja }}
                        </div>
                      </v-alert>
                    </v-col>
                    <v-col cols="4" class="pb-0">
                      <small class="font-weight-bold">Número</small>
                      <v-alert
                          border="left"
                          color="black"
                          dense
                          :icon="false"
                          outlined
                          class="text-center py-1"
                      >
                        <div style="color:black; font-size: 13px">
                          {{ indice.numero }}
                        </div>
                      </v-alert>
                    </v-col>
                    <v-col cols="4" class="pb-0">
                      <small class="font-weight-bold">Año</small>
                      <v-alert
                          border="left"
                          color="black"
                          dense
                          :icon="false"
                          outlined
                          class="text-center py-1"
                      >
                        <div style="color:black; font-size: 13px">
                          {{ indice.anho }}
                        </div>
                      </v-alert>
                    </v-col>
                  </div>
                </div>
              </v-card-text>

            </v-card>
          </div>
        </v-fade-transition>


      </v-col>

      <v-col sm="12" md="4" class=" pl-sm-6">
        <InformacionDocumento :certificado="certificado" v-if="!load.certificado"></InformacionDocumento>
      </v-col>
    </v-row>
    <modal-mensaje-simple-reutilizable
        :ver-dialog="verModalMensajeReutilizable"
        :mensaje-titulo="mensajeTituloModal"
        :mensaje="mensajeModal"
        :time="parseInt(10)"
        @ocultarDialog="verModalMensajeReutilizable = $event"
    ></modal-mensaje-simple-reutilizable>
    <modal-documento-agregado :verDialog="modalAgregar.verModalDocumentoAgregado"
                              :documentosAgregados="modalAgregar.lista_agregados"
                              :documentosRepetidos="modalAgregar.documentos_repetidos"
                              :documentosEnLimite="modalAgregar.limite_carro"
                              @ocultarDialog="modalAgregar.verModalDocumentoAgregado = false"></modal-documento-agregado>
    <modal-serie :ver-dialog-serie="dialogSerie" :serie="serieValidada" @cerrarModalSerie="dialogSerie = false"
                 @recargarBusqueda="recargarModalSerie()"
                 @agregarDocumentos="corregirFoja($event)"></modal-serie>
  </div>
</template>

<script>
import PanelJurisdiccion from "@/views/index/inscripciones/components/PanelJurisdiccion";
import InformacionDocumento from "@/components/SolicitudDocumentos/InformacionDocumento";
import FNA from "@/components/SolicitudDocumentos/Formularios/FormularioFna";
import registrosEntity from "@/model/registros/registros.entity";
import registros, {
  ID_AREA_AGRUPACION,
  ID_TIPO_CERTIFICADO,
  ID_TIPO_INDICE,
  ID_TIPO_REGISTRO
} from "@/model/registros/registros.entity";
/*import {DIVISA} from "@/model/inscripciones/inscripciones.entity";
import carroEntity from "@/model/carro/carro.entity";*/
import ModalDocumentoAgregado from "@/components/ModalDocumentoAgregado";
import ModalSerie from "@/components/SolicitudDocumentos/modals/ModalSerie";
import tituloEntity from "@/model/titulo/titulo.entity";
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import carro from "@/model/carro/carro.entity";
import ModalMensajeSimpleReutilizable from "@/components/Dialogs/ModalMensajeSimpleReutilizable.vue";
import store from "@/store";

export default {
  name: "AgregarAlzPrhServiuPv",
  components: {
    ModalMensajeSimpleReutilizable,
    ModalSerie, ModalDocumentoAgregado, FNA, InformacionDocumento, PanelJurisdiccion
  },
  data() {
    return {
      jurisdiccionSeleccionada: false,
      certificado: {},
      load: {
        certificado: false
      },
      observacion: "",
      indice: null,
      indice2: null,
      has_gp: false,
      certificado_gp: {},
      formularioDatosParaCarrito: {
        anho: null,
        foja: null,
        id_tipo_formulario: 1,
        numero: null
      },
      dataFormulario: null,
      modalAgregar: {
        verModalDocumentoAgregado: false,

      },
      dialogSerie: false,
      serieValidada: {},
      //-----------------------------
      fojaTitulo: null,
      numeroTitulo: null,
      anhoTitulo: null,
      alzamientoServiu: null,
      alzamientos: null,
      tituloSeleccionado: null,
      prohibicionSERVIU: true,
      verModalMensajeReutilizable: false,
      mensajeTituloModal: '',
      mensajeModal: '',
      loadingBtnComprobar: false,
      hipoteca: [],
      copiaAutorizadaDeProhibicion: [],
      copiaAutorizada_sinVigencia: [],
      copiaConVigenciaODominioVigente: [],
      certificado_copiaAutorizada_sinVigencia: null,
      certificado_copiaAutorizadaProhibicion: null,
      certificado_copiaConVigenciaODominoVigente: null,
      tiene_copiaAutorizada_sinVigencia: false,
      tiene_copiaAutorizadaProhibicion: false,
      tiene_copiaConVigenciaODominoVigente: false,

    }
  },

  created() {
    registrosEntity.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.ALZAMIENTO_SERVIU_PLAZO_VENCIDO, ID_TIPO_CERTIFICADO.CERT_HIPOTECAS_GRAVAMENES).then(res => {
      this.certificado_gp = res;
    })

    registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.ALZAMIENTO_SERVIU_PLAZO_VENCIDO, ID_TIPO_CERTIFICADO.ALZAMIENTO_PLAZO_VENCIDO).then(r => {
      this.alzamientoServiu = r;
    })

    registrosEntity.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.ALZAMIENTO_SERVIU_PLAZO_VENCIDO, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA).then(respuesta => {
      this.certificado_copiaAutorizada_sinVigencia = respuesta;
    })

    registrosEntity.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.ALZAMIENTO_SERVIU_PLAZO_VENCIDO, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_PROHIBICION).then(respuesta => {
      this.certificado_copiaAutorizadaProhibicion = respuesta;
    })

    registrosEntity.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.ALZAMIENTO_SERVIU_PLAZO_VENCIDO, ID_TIPO_CERTIFICADO.COPIA_CON_VIGENCIA_O_DOMINIO_VIGENTE).then(respuesta => {
      this.certificado_copiaConVigenciaODominoVigente = respuesta;
    })
    //this.dataFormulario = carro.getDatosAdicionalesCertificado(ID_TIPO_REGISTRO.ALZAMIENTO_SERVIU_PLAZO_VENCIDO, ID_TIPO_CERTIFICADO.ALZAMIENTO_PLAZO_VENCIDO);

    this.dataFormulario = store.state.carro.filter(itemCarro => {
      if (itemCarro.id_tipo_certificado_FK == ID_TIPO_CERTIFICADO.ALZAMIENTO_PLAZO_VENCIDO && itemCarro.id_tipo_registro_FK == ID_TIPO_REGISTRO.ALZAMIENTO_SERVIU_PLAZO_VENCIDO) {
        return itemCarro;
      }
    })

    if (this.dataFormulario.length === 0) {
      this.dataFormulario = null
    }

    if (this.dataFormulario) {

      let indiceTitulo;
      this.dataFormulario[0].datos_adicionales.map(titulo => {
        if (titulo.nombre == "titulo") {
          indiceTitulo = titulo.valor
        }
      })

      if (indiceTitulo != null) {
        let ind_split = indiceTitulo.split("-");
        this.fojaTitulo = ind_split[0]
        this.numeroTitulo = ind_split[1]
        this.anhoTitulo = ind_split[2]
      }

      let indiceProhibicion;
      this.dataFormulario[0].datos_adicionales.map(prohibicion => {
        if (prohibicion.nombre == "prohibicion") {
          indiceProhibicion = prohibicion.valor
        }
      })

      if (indiceProhibicion != null) {
        let ind_split2 = indiceProhibicion.split("-");
        let indice = {
          foja: ind_split2[0],
          numero: ind_split2[1],
          anho: ind_split2[2]
        }
        this.indice = indice;
      }
    }

  },
  methods: {
    recargarModalSerie() {
      this.validarIndice();
    },
    corregirFoja() {
      this.indice.foja = this.serieValidada.foja
      this.agregarAlCarro();
      this.dialogSerie = false;
    },
    setFormulario(indice) {
      this.indice = indice;
    },
    setFormulario2(indice) {
      this.indice2 = indice;
    },
    confirmarJurisdiccion() {
      this.jurisdiccionSeleccionada = true
    },

    validarIndice() {
      registrosEntity.validarIndice(this.indice.numero, this.indice.anho, ID_TIPO_INDICE.PROHIBICION).then(res => {
        if (res) {

          this.serieValidada = res;
          this.serieValidada.fojaBuscada = this.indice.foja;
          // if (this.indice.foja == res.foja) {
          //   this.agregarAlCarro()
          // } else {
          this.dialogSerie = true;
          // }

        }
      })
    },
    /*
        agregarAlCarro() {
          let datos_adicionales = {
            observaciones: this.observacion,
            indice: this.indice.foja + "-" + this.indice.numero + "-" + this.indice.anho
          }

          let certificado = this.certificado;


          let listaCarro = []

          let item = {
            id_tipo_registro_FK: certificado.id_tipo_registro_FK,
            id_tipo_certificado: certificado.id_tipo_certificado,
            precio_pagado: certificado.precio,
            precio_cliente: certificado.precio,
            tipo_registro: certificado.tipo_registro,
            tipo_certificado: certificado.tipo_certificado,
            id_divisa: DIVISA.CLP,
            numero_copias: 1,
            id_area_agrupacion: certificado.id_area_agrupacion
          };

          listaCarro.push(item)

          if (this.has_gp) {
            certificado = this.certificado_gp;

            let item2 = {
              id_tipo_registro_FK: certificado.id_tipo_registro_FK,
              id_tipo_certificado: certificado.id_tipo_certificado,
              precio_pagado: certificado.precio,
              precio_cliente: certificado.precio,
              tipo_registro: certificado.tipo_registro,
              tipo_certificado: certificado.tipo_certificado,
              id_divisa: DIVISA.CLP,
              numero_copias: 1,
              id_area_agrupacion: certificado.id_area_agrupacion
            }


            listaCarro.push(item2)
          }


          carroEntity.agregarAlCarroInscripciones(listaCarro, datos_adicionales).then(response => {
            this.modalAgregar.verModalDocumentoAgregado = true;
            this.modalAgregar.documentos_repetidos = response.documentos_repetidos;
            this.modalAgregar.limite_carro = response.limite_carro
            this.modalAgregar.lista_agregados = response.lista_agregados;
            this.modalCotizar = false;

          })
        },*/
    //------------------------------------------------------------------------------------------------------------------
    comprobarSiPuedeComprar() {
      if (this.indice2) {
        this.loadingBtnComprobar = true
        this.fojaTitulo = this.indice2.foja
        this.numeroTitulo = this.indice2.numero
        this.anhoTitulo = this.indice2.anho

        let objeto_titulo_has_prohibiciones = {
          anho_FK: this.anhoTitulo,
          numero_FK: this.numeroTitulo,
          id_acompanamiento_FK: 1
        }
        tituloEntity.getHipotecaProhibicionTitulo(objeto_titulo_has_prohibiciones).then(response => {
          console.log(response.data.obj)
          this.loadingBtnComprobar = false
          if (response.data.obj) {
            this.prohibicionSERVIU = false
            this.indice = {
              foja: response.data.obj.foja_prohibicion_FK.toString(),
              numero: response.data.obj.numero_prohibicion_FK.toString(),
              anho: response.data.obj.anho_prohibicion_FK.toString()
            }
          } else {
            this.indice = undefined
            this.prohibicionSERVIU = true
            this.verModalMensajeReutilizable = true
            this.mensajeTituloModal = 'No es posible solicitar el Alzamiento'
            this.mensajeModal = 'Según nuestros registros los datos citados no se hacen corresponder con una Prohibición disponible para su alzamiento'
          }
        })
      } else {
        this.verModalMensajeReutilizable = true
        this.mensajeTituloModal = 'Debe ingresar FNA del titulo'
        this.mensajeModal = 'Para poder realizar la compra de documentos debe ingresar los datos del titulo'
      }
    },

    enviarAlCarro() {
      this.fojaTitulo = this.indice2.foja
      this.numeroTitulo = this.indice2.numero
      this.anhoTitulo = this.indice2.anho

      this.formularioDatosParaCarrito.anho = this.anhoTitulo
      this.formularioDatosParaCarrito.foja = this.fojaTitulo
      this.formularioDatosParaCarrito.numero = this.numeroTitulo
      console.log("titulo", this.fojaTitulo, this.numeroTitulo, this.anhoTitulo)
      console.log("prohibición", this.indice.foja, this.indice.numero, this.indice.anho)
      let items_alz = [];

      tituloEntity.getHipotecasYGravamenesTitulo(this.numeroTitulo, this.anhoTitulo).then(t => {
        this.alzamientos = [];
        this.tituloSeleccionado = t.obj
        console.log("titulo cargado: ", t);

        if (this.tituloSeleccionado != null) {

          if (this.tituloSeleccionado.hipotecas.length > 0 || this.tituloSeleccionado.prohibiciones.length > 0) {
            this.$emit("mostrar-mensaje-nuevas-funciones")
          }


          let hip = {
            numero_copias: 1,
            indice: "No aplica",
            tipo_certificado: "Cert. de Hip. y Gravámenes",
            precio_total: this.certificado_gp.precio
          }
          this.hipoteca.push(hip)

          this.tituloSeleccionado.prohibiciones.map(p => {
            this.$set(p, "numero_copias", 1)
            let alz = {
              numero_copias: 1,
              indice: `${p.foja_prohibicion}${p.acompanamiento_foja}-${p.numero_prohibicion}${p.acompanamiento}-${p.anho_prohibicion}`,
              tipo_certificado: "Alzamiento de Prohibicion SERVIU",
              precio_total: this.alzamientoServiu.precio
            }
            this.alzamientos.push(alz)
          })

          let copiaAutorizadaProhivicion = {
            numero_copias: 1,
            indice: "No aplica",
            tipo_certificado: "Copia Autorizada Prohibición",
            precio_total: this.certificado_copiaAutorizadaProhibicion.precio
          }
          this.copiaAutorizadaDeProhibicion.push(copiaAutorizadaProhivicion)

          let copiaAutorizada = {
            numero_copias: 1,
            indice: "No aplica",
            tipo_certificado: "Copia Autorizada (sin vigencia)",
            precio_total: this.certificado_copiaAutorizada_sinVigencia.precio
          }
          this.copiaAutorizada_sinVigencia.push(copiaAutorizada)

          let copiaDominioVigente = {
            numero_copias: 1,
            indice: "No aplica",
            tipo_certificado: "Copia con Vigencia o Dominio Vigente",
            precio_total: this.certificado_copiaConVigenciaODominoVigente.precio
          }
          this.copiaConVigenciaODominioVigente.push(copiaDominioVigente)
        }
        this.alzamientos.map(a => {
          if (a.numero_copias > 0) {
            let copiaAlzamientoServiu = {...this.alzamientoServiu}
            copiaAlzamientoServiu.precio = a.precio_total
            items_alz.push({
              ...copiaAlzamientoServiu,
              indice: "No aplica",
              numero_copias: a.numero_copias
            })
            this.$set(this.formularioDatosParaCarrito, "id_tipo_formulario", ID_TIPO_FORMULARIO.FNA)
            this.$set(this.formularioDatosParaCarrito, "datos_adicionales", [{
              nombre: "titulo",
              valor: this.formularioDatosParaCarrito.foja + "-" + this.formularioDatosParaCarrito.numero + "-" + this.formularioDatosParaCarrito.anho,
              id_tipo_formulario: ID_TIPO_FORMULARIO.FNA,
              id_area_agrupacion: ID_AREA_AGRUPACION.ALZAMIENTO_PROHIBICION_PLAZO_VENCIDO
            }, {
              nombre: "prohibicion",
              valor: a.indice,
              id_tipo_formulario: ID_TIPO_FORMULARIO.FNA,
              id_area_agrupacion: ID_AREA_AGRUPACION.ALZAMIENTO_PROHIBICION_PLAZO_VENCIDO
            },

            ])
          }
        });

        if (this.has_gp) {
          this.hipoteca.map(hip => {
            if (hip.numero_copias > 0) {
              let copiaHipotecaYGravamen = {...this.certificado_gp}
              copiaHipotecaYGravamen.precio = hip.precio_total
              items_alz.push({
                ...copiaHipotecaYGravamen,
                indice: this.fojaTitulo + "-" + this.numeroTitulo + "-" + this.anhoTitulo,
                numero_copias: hip.numero_copias
              })
            }
          })
        }

        if (this.tiene_copiaAutorizadaProhibicion) {
          this.copiaAutorizadaDeProhibicion.map(CADP => {
            if (CADP.numero_copias > 0) {
              let copiaAutorizadaProhivicion = {...this.certificado_copiaAutorizadaProhibicion}
              copiaAutorizadaProhivicion.precio = CADP.precio_total
              copiaAutorizadaProhivicion.precio_cliente = CADP.precio_total
              items_alz.push({
                ...copiaAutorizadaProhivicion,
                indice: this.indice.foja + "-" + this.indice.numero + "-" + this.indice.anho,
                numero_copias: CADP.numero_copias,
                precio_cliente: CADP.precio_total
              })
            }
          })
        }

        if (this.tiene_copiaAutorizada_sinVigencia) {
          this.copiaAutorizada_sinVigencia.map(CASV => {
            if (CASV.numero_copias > 0) {
              let copiaAutorizada = {...this.certificado_copiaAutorizada_sinVigencia}
              copiaAutorizada.precio = CASV.precio_total
              items_alz.push({
                ...copiaAutorizada,
                indice: this.fojaTitulo + "-" + this.numeroTitulo + "-" + this.anhoTitulo,
                numero_copias: CASV.numero_copias
              })
            }
          })
        }

        if (this.tiene_copiaConVigenciaODominoVigente) {
          console.log("entre copia con vigencia")
          this.copiaConVigenciaODominioVigente.map(CCVDV => {
            console.log("entre copia con vigencia map")
            if (CCVDV.numero_copias > 0) {
              let dominioVigente = {...this.certificado_copiaConVigenciaODominoVigente}
              dominioVigente.precio = CCVDV.precio_total
              console.log("entre copia con vigencia antes del push")
              items_alz.push({
                ...dominioVigente,
                indice: this.fojaTitulo + "-" + this.numeroTitulo + "-" + this.anhoTitulo,
                numero_copias: CCVDV.numero_copias
              })
            }
          })
        }

        let data2 = null;
        if (items_alz.length > 0) {
          data2 = {
            ...this.formularioDatosParaCarrito,
            id_tipo_formulario: ID_TIPO_FORMULARIO.ALZAMIENTO_PLAZO_VENCIDO
          }
          this.formularioDatosParaCarrito.datos_adicionales = null;
        }
        carro.agregarAlCarro(items_alz, data2, 0).then(r2 => {
          console.log('r2 es, ', r2)
          this.modalAgregar.verModalDocumentoAgregado = true;
          this.modalAgregar.documentos_repetidos = r2.documentos_repetidos;
          this.modalAgregar.limite_carro = r2.limite_carro
          this.modalAgregar.lista_agregados = r2.lista_agregados;
          this.modalCotizar = false;
        })
        console.log(items_alz)
      });

    }
  }
}
</script>

<style scoped>

</style>
