<template>
    <div class="px-0 pt-2">
        <div class="d-flex pb-1">
            <v-select
                    :items="filtrarDocumentos"
                    item-text="nombre_V_Select"
                    v-model="objetoSeleccionado"
                    return-object
                    hide-details
                    dense
                    outlined
                    :key="upKey"
                    :disabled="desactivarSelect"
            ></v-select>
            <v-btn
                    @click="agregarItem(objetoSeleccionado)"
                    style="height: 40px"
                    outlined color="success"
                    class="ml-2"
                    :disabled="desactivarSelect"
            >
                <v-icon small left>mdi-plus</v-icon>
                Agregar
            </v-btn>
        </div>

        <!-- <div v-for="cert in filtrarDocumentos" :key="cert.tipo_certificado">

                    <v-row>
                        <v-col cols="6" class="d-flex align-center">
                            <p class="mb-0 font-weight-medium">{{ cert.tipo_certificado }}</p>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center">
                            <p v-if="cert.enCarro > 0" class="mb-0 font-weight-medium">
                                <small v-if="cert.enCarro == 1">Cotizado</small>
                                <small v-if="cert.enCarro > 1">{{ cert.enCarro }} cotizados</small></p>
                        </v-col>
                        <v-col cols="4" class="text-center">
                            <v-btn @click="agregarItem(cert)" small outlined color="success">
                                <v-icon small left>mdi-plus</v-icon>
                                Agregar
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>

                </div>-->
        <v-row class="d-flex" v-if="mostrarIfInscripcionPorPropiedad === true">
            <v-col cols="6" class="py-0">
                <v-checkbox
                        class="py-0"
                        v-model="incluir_GP"
                        hide-details
                        label="Incluir Cert. de Hipotecas y Prohibiciones"></v-checkbox>
            </v-col>
            <v-col cols="6" class="py-0">
                <v-checkbox
                        class="py-0"
                        v-model="incluir_litigio"
                        hide-details
                        label="Incluir Certificado de Litigios"></v-checkbox>
            </v-col>

        </v-row>

        <modal-mensaje-nuevas-funciones
                :ver-dialog="verModalMensaje"
                @ocultarDialog="verModalMensaje = $event"
                mensaje="De momento no es posible cotizar este tipo de trámite, por favor comuníquese al correo cotizaciones@cbrls.cl"
                titulo="Problemas en la cotización"
        ></modal-mensaje-nuevas-funciones>
        <modal-form1 :ver-dialog="modals.form1" @ocultarDialog="ocultarDialog" :certificado="certificado_seleccionado"
                     @agregarCertificado="agregarCertificado($event)"
                     @mostrarModalInfoOError="verModalMensaje = $event"></modal-form1>
        <modal-form2 :ver-dialog="modals.form0" @ocultarDialog="ocultarDialog" :certificado="certificado_seleccionado"
                     @agregarCertificado="agregarCertificado($event)"
                     @mostrarModalInfoOError="verModalMensaje = $event"></modal-form2>
        <modal-form3 :ver-dialog="modals.form2" @ocultarDialog="ocultarDialog" :certificado="certificado_seleccionado"
                     @agregarCertificado="agregarCertificado"
                     @mostrarModalInfoOError="verModalMensaje = $event"></modal-form3>

    </div>
</template>

<script>
import ModalForm1 from "@/views/index/inscripciones/propiedad/inscripcion-propiedad/modal-formularios/ModalForm1";
import ModalForm2 from "@/views/index/inscripciones/propiedad/inscripcion-propiedad/modal-formularios/ModalForm2";
import ModalForm3 from "@/views/index/inscripciones/propiedad/inscripcion-propiedad/modal-formularios/ModalForm3";
import registrosEntity, {ID_AGRUPACION_DOCUMENTO, ID_TIPO_CERTIFICADO} from "@/model/registros/registros.entity";
import ModalMensajeNuevasFunciones from "@/components/Tramites/ModalMensajeNuevasFunciones.vue";

export default {
    name: "ListaCertificadosInscripciones",
    components: {ModalMensajeNuevasFunciones, ModalForm3, ModalForm2, ModalForm1},
    props: {
        listaPorAgregar: {
            default: []
        },
        desactivarSelectProps: {
            default: true
        },
    },
    data() {
        return {
            verModalMensaje: false,
            lista_certificados: [],
            modals: {
                form0: false,
                form1: false,
                form2: false,

            },
            certificado_seleccionado: {},
            tempCertificado: null,
            incluir_GP: false,
            incluir_litigio: false,
            objetoSeleccionado: Object,
            upKey: 0,
            mostrarIfInscripcionPorPropiedad: false,
            desactivarSelect: true,
        }
    },
    watch: {
        desactivarSelectProps() {
            this.desactivarSelect = this.desactivarSelectProps
        },
        listaPorAgregar() {
            this.mostrarIfInscripcionPorPropiedad = false
            this.lista_certificados.map(lis => {
                lis.enCarro = this.listaPorAgregar.filter(f => {
                    return f.id_tipo_certificado == lis.id_tipo_certificado
                }).length

            })
            this.listaPorAgregar.map(filter => {
                if (filter.id_tipo_certificado === 101) {
                    this.mostrarIfInscripcionPorPropiedad = true
                }
            })
            this.actualizarNombreDelSelect()
        },

        incluir_GP() {
            this.$emit("actualizarGP", this.incluir_GP);
        },
        incluir_litigio() {
            this.$emit("actualizarLitigio", this.incluir_litigio);
        }
    },
    created() {
        registrosEntity.getCertificadosByTipoRegistro("inscripcion-propiedad", ID_AGRUPACION_DOCUMENTO.INSCRIPCIONES).then(res => {
            res.map(r => {
                r.enCarro = 0;
            })
            this.lista_certificados = res;
            console.log('respuesta back', res)
            this.$emit("guardarCertificados", this.lista_certificados.find(f => {
                console.log('filtro gp', f)
                this.actualizarNombreDelSelect()
                return f.id_tipo_certificado === ID_TIPO_CERTIFICADO.CERT_HIPOTECAS_GRAVAMENES
            }), this.lista_certificados.find(f => {
                console.log('filtro litigio', f, f.id_tipo_certificado === ID_TIPO_CERTIFICADO.LITIGIOS)
                this.actualizarNombreDelSelect()
                return f.id_tipo_certificado === ID_TIPO_CERTIFICADO.LITIGIOS
            }))
        })

    },
    methods: {
        actualizarNombreDelSelect() {
            console.log("v-select", this.lista_certificados)
            this.lista_certificados.map(objeto => {
                objeto.nombre_V_Select = ''
                if (objeto.enCarro === 0) {
                    objeto.nombre_V_Select = objeto.tipo_certificado
                } else if (objeto.enCarro === 1) {
                    objeto.nombre_V_Select = objeto.tipo_certificado + ' (Cotizado)'
                } else if (objeto.enCarro > 1) {
                    objeto.nombre_V_Select = objeto.tipo_certificado + ' (' + objeto.enCarro + ' cotizado)'
                }
                console.log(objeto)
            })
            this.upKey++
        },
        agregarItem(cert) {
            console.log('agregar item?,', cert)
            this.certificado_seleccionado = cert;
            switch (cert.tipo_formulario) {
                case 0 :
                    this.modals.form0 = true;
                    break;
                case 1 :
                    this.modals.form1 = true;
                    break;
                case 2:
                    this.modals.form2 = true;
                    break;
            }
        },
        ocultarDialog() {
            this.modals.form0 = false;
            this.modals.form1 = false;
            this.modals.form2 = false;
        },
        agregarCertificado(certificado, certificado2) {
            this.ocultarDialog();
            this.$emit("agregarCertificado", certificado, certificado2);
        },
    },
    computed: {
        filtrarDocumentos() {
            return this.lista_certificados.filter(f => {
                return f.id_tipo_certificado !== ID_TIPO_CERTIFICADO.CERT_HIPOTECAS_GRAVAMENES && f.id_tipo_certificado !== ID_TIPO_CERTIFICADO.LITIGIOS
            });
        }
    }
}
</script>

<style scoped>

</style>
