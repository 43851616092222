<template>
    <div>
        <v-card class="mt-3 px-4">
            <v-card-title>
                Documentos cotizados por agregar al carro
            </v-card-title>
            <v-card-text>
                <transition name="fade">
                    <p class="text-center my-2" v-if="lista.length == 0">No se ha cotizado ningún trámite</p>
                </transition>
                <transition name="fade">
                    <div v-if="lista.length > 0">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">Certificado</th>
                                    <th class="text-center">Cantidad</th>
                                    <th class="text-center">Precio propiedad</th>
                                    <th class="text-center">Precio trámite</th>
                                    <th style="width: 56px"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        v-for="(item, i) in lista"
                                        :key="i + '-'+ item.tipo_certificado"
                                >
                                    <td>{{ item.tipo_certificado }}</td>
                                    <td class="text-center">{{ item.numero_copias }}</td>
                                    <td class="text-center" v-if="item.id_divisa == ID_DIVISA.CLP">
                                        {{ item.precio_cliente | clp }}
                                    </td>
                                    <td class="text-center" v-if="item.id_divisa == ID_DIVISA.UF">
                                        {{ item.precio_cliente | number }} UF
                                    </td>
                                    <td class="text-center" v-if="item.id_divisa == ID_DIVISA.NA">N.A.</td>
                                    <td class="text-center">{{ item.precio_pagado * item.numero_copias | clp }}</td>
                                    <td class="text-center"><a @click="eliminarElemento(item)">
                                        <v-icon color="error">mdi-delete</v-icon>
                                    </a></td>
                                </tr>

                                <tr v-if="incluir_gp && copias_gp > 0">
                                    <td>{{ gp.tipo_certificado }}</td>
                                    <td class="text-center">{{ copias_gp }}</td>
                                    <td class="text-center">N.A.</td>
                                    <td class="text-center">{{ precio_total_gp | clp }}</td>
                                    <td class="text-center">-</td>
                                </tr>
                                <tr v-if="incluir_litigio && copias_litigio > 0">
                                    <td>{{ litigio.tipo_certificado }}</td>
                                    <td class="text-center">{{ copias_litigio }}</td>
                                    <td class="text-center">N.A.</td>
                                    <td class="text-center">{{ precio_total_litigio | clp }}</td>
                                    <td class="text-center">-</td>
                                </tr>

                                </tbody>
                            </template>
                        </v-simple-table>

                    </div>

                </transition>
                <transition name="fade">
                    <div class="pt-3">
                        <v-divider class="mb-3"></v-divider>
                        <v-textarea v-model="observacion" :disabled="observacionCargada != null" outlined
                                    label="Observaciones"
                                    hide-details="auto"
                                    counter="200" auto-grow clearable
                                    :rules="[rules.length(200)]"></v-textarea>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="text-center">
                        <!--            <informativo-certificacion></informativo-certificacion>-->
                        <v-btn color="info" :disabled="observacion.length > 200 || lista.length == 0 "
                               @click="evaluarCarro" outlined small
                               class="mt-4">
                            <v-icon small left>mdi-cart-plus</v-icon>
                            Agregar documentos al carro
                        </v-btn>
                    </div>
                </transition>

            </v-card-text>
        </v-card>

        <modal-documento-agregado :verDialog="modalAgregar.verModalDocumentoAgregado"
                                  :documentosAgregados="modalAgregar.lista_agregados"
                                  :documentosRepetidos="modalAgregar.documentos_repetidos"
                                  :documentosEnLimite="modalAgregar.limite_carro"
                                  @ocultarDialog="modalAgregar.verModalDocumentoAgregado = false"></modal-documento-agregado>
        <modal-agregar-copia :ver-dialog="modals.agregarCopia" @ocultarDialog="modals.agregarCopia = false"
                             :certificados="lista" :id_tipo_registro="ID_TIPO_REGISTRO.INSCRIPCION_PROPIEDAD"
                             @agregarCertificado="agregarCopiasAlCarro"></modal-agregar-copia>
    </div>
</template>

<script>
import {DIVISA} from "@/model/inscripciones/inscripciones.entity";
import ModalDocumentoAgregado from "@/components/ModalDocumentoAgregado";

// eslint-disable-next-line no-unused-vars
import carroEntity from "@/model/carro/carro.entity";
// import InformativoCertificacion from "@/views/index/inscripciones/components/InformativoCertificacion";
import {ID_TIPO_CERTIFICADO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import ModalAgregarCopia
    from "@/views/index/inscripciones/propiedad/inscripcion-propiedad/modal-formularios/ModalAgregarCopia";

export default {
    name: "InscripcionesViewListaCarro",
    components: {ModalAgregarCopia, ModalDocumentoAgregado},
    props: {
        lista: {
            type: Array,
            default: () => []
        },
        datos: {
            type: Object,
            default: () => ({})
        },
        observacionCargada: {default: null},
        gp: {
            type: Object,
            default: () => {
            }
        },
        litigio: {
            type: Object,
            default: () => {
            }
        },
        numeroF2890:{
            default: 0
        }
    },
    data() {
        return {
            ID_DIVISA: DIVISA,
            modalAgregar: {
                verModalDocumentoAgregado: false,

            },
            observacion: "",
            rules: {
                length: len => v => (v || '').length < len || `Porfavor, escriba su observación en un máximo de ${len} caracteres`
            },
            modals: {
                agregarCopia: false
            },
            ID_TIPO_REGISTRO: ID_TIPO_REGISTRO,
            incluir_gp: false,
            incluir_litigio: false
        }
    },
    watch: {
        observacionCargada() {
            if (this.observacionCargada != null) {
                this.observacion = this.observacionCargada + "";
            }
        }
    },

    created() {
        if (this.observacionCargada != null) {
            this.observacion = this.observacionCargada + "";
        }
    },

    computed: {
        copias_gp() {
            let cantidad = 0;
            this.lista.map(item => {
                if (item.id_tipo_certificado === ID_TIPO_CERTIFICADO.INCRIPCION_PROPIEDAD_CON_COPIA_VIGENCIA) {
                    cantidad += parseInt(item.numero_copias)
                }
            })
            return cantidad;
        },
        precio_total_gp() {
            let cantidad = 0;
            this.lista.map(item => {
                if (item.id_tipo_certificado === ID_TIPO_CERTIFICADO.INCRIPCION_PROPIEDAD_CON_COPIA_VIGENCIA) {
                    cantidad += parseInt(item.numero_copias)
                }
            })
            return cantidad * this.gp.precio_cliente
        },
        copias_litigio() {
            let cantidad = 0;
            this.lista.map(item => {
                if (item.id_tipo_certificado === ID_TIPO_CERTIFICADO.INCRIPCION_PROPIEDAD_CON_COPIA_VIGENCIA) {
                    cantidad += parseInt(item.numero_copias)
                }
            })
            return cantidad;
        },
        precio_total_litigio() {
            let cantidad = 0;
            this.lista.map(item => {
                if (item.id_tipo_certificado === ID_TIPO_CERTIFICADO.INCRIPCION_PROPIEDAD_CON_COPIA_VIGENCIA) {
                    cantidad += parseInt(item.numero_copias)
                }
            })
            return cantidad * this.litigio.precio_cliente
        }
    },
    methods: {
        actualizarGP(incluir) {
            console.log("incluir gp? ", incluir)
            this.incluir_gp = incluir
        },
        actualizarLitigio(incluir) {
            console.log("incluir litigio? ", incluir)
            this.incluir_litigio = incluir
        },

        eliminarElemento(item) {
            this.$emit("eliminarElemento", item);
        },
        evaluarCarro() {
            let copias_posibles = this.buscarItemsPosibleCopias();
            console.log("posibles copias: ", copias_posibles);
            if (copias_posibles > 0) {
                this.modals.agregarCopia = true;
            } else {
                this.agregarAlCarro();
            }
        },
        agregarCopiasAlCarro(lista_copias) {

            lista_copias.map(l => {
                this.$emit("agregarCertificado", l)
            })

            this.modals.agregarCopia = false;
            this.agregarAlCarro();
        },

        agregarAlCarro() {
            // eslint-disable-next-line no-unused-vars
            let datos_adicionales
            console.log("numero",this.numeroF2890)
            if(this.numeroF2890 === 0){
                datos_adicionales = {
                    id_documento_electronico: this.datos.idDocumento,
                    notaria: this.datos.notarioSeleccionado.nombre,
                    observaciones: this.observacion,
                }
            }else{
                datos_adicionales = {
                    id_documento_electronico: this.datos.idDocumento,
                    notaria: this.datos.notarioSeleccionado.nombre,
                    observaciones: this.observacion,
                    numero_F2890: this.numeroF2890
                }
            }

            let items_carro = [...this.lista]
            console.log("el gp_ ", this.gp)
            if (this.incluir_gp) {
                items_carro.push({
                    ...this.gp,
                    numero_copias: this.copias_gp,
                    precio_cliente: this.gp.precio_cliente
                })

            }
            if (this.incluir_litigio) {
                items_carro.push({
                    ...this.litigio,
                    numero_copias: this.copias_litigio,
                    precio_cliente: this.litigio.precio_cliente
                })
            }

            console.log("items carro: ", items_carro)

            carroEntity.agregarAlCarroInscripciones(items_carro, datos_adicionales).then(response => {
                this.modalAgregar.verModalDocumentoAgregado = true;
                this.modalAgregar.documentos_repetidos = response.documentos_repetidos;
                this.modalAgregar.limite_carro = response.limite_carro
                this.modalAgregar.lista_agregados = response.lista_agregados;

            })
        },


        buscarItemsPosibleCopias() {

            let tipos_certificados_con_copias = [ID_TIPO_CERTIFICADO.INSCRIPCION_DE_PROPIEDAD, ID_TIPO_CERTIFICADO.INSCRIPCION_DE_HIPOTECA, ID_TIPO_CERTIFICADO.INSCRIPCION_DE_PROHIBICION]
            let isIn;
            let copias = 0;


            this.lista.map(item => {

                isIn = tipos_certificados_con_copias.find(f => {
                    return f == item.id_tipo_certificado
                })
                if (isIn != null) {
                    copias += item.numero_copias
                }
            })
            return copias;
        }
    }
}
</script>

<style scoped>
.fade-enter-active {
    transition: opacity .5s
}

.fade-leave-active {
    transition: opacity 0s
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0
}
</style>
