<template>
  <v-dialog max-width="550" v-model="ver">
    <v-card class="px-4">
      <v-card-title class="pb-0">
        {{ certificado.tipo_certificado }}
      </v-card-title>
      <v-card-text>
        <p class="">Ingrese la cantidad de documentos que solicitará</p>
        <div>
          <v-text-field autofocus outlined dense hide-details label="Cantidad" v-model="cantidad" type="number">
          </v-text-field>
        </div>
        <div class="text-center mt-5">
          <v-btn small outlined color="grey" class="mr-1" @click="hideDialog">
            <v-icon left small>mdi-close</v-icon>
            Volver
          </v-btn>
          <v-btn small outlined color="success" class="ml-1" @click="agregarCertificado"
                 :disabled="cantidad == null || cantidad == 0">
            <v-icon left small>mdi-check</v-icon>
            Confirmar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import inscripcionesEntity, {DIVISA} from "@/model/inscripciones/inscripciones.entity";

export default {
  name: "ModalForm2",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    certificado: {default: Object},

  },
  data() {
    return {
      ver: false,
      cantidad: null,
      ID_DIVISA: DIVISA
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      } else {
        this.cantidad = null;
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    agregarCertificado() {

      let precio_calculado
      inscripcionesEntity.getPrecioCalculadoDocumento(this.certificado.id_tipo_registro_FK, this.certificado.id_tipo_certificado, 0, this.ID_DIVISA.NA).then(res => {
        if(res.status == true) {
          precio_calculado = res.obj;

          let certificado = {
            id_tipo_registro_FK: this.certificado.id_tipo_registro_FK,
            id_tipo_certificado: this.certificado.id_tipo_certificado,
            precio_pagado: precio_calculado,
            precio_cliente: precio_calculado,
            tipo_registro: this.certificado.tipo_registro,
            tipo_certificado: this.certificado.tipo_certificado,
            id_divisa: this.ID_DIVISA.NA,
            numero_copias: this.cantidad,
            id_area_agrupacion: this.certificado.id_area_agrupacion
          };
          this.$emit("agregarCertificado", certificado)
        }else{
          this.$emit("mostrarModalInfoOError", true)
          this.hideDialog()
        }
        console.log('soy el res', res)
      })


    }
  }
}
</script>

<style scoped>

</style>
