<template>
  <div>
    <v-row>

      <v-col cols="12" md="8" class="pr-md-0">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            {{ title }}
          </v-card-title>

          <v-card-text v-if="dataFormulario == null" class="pt-4">

            <formulario-carga-p-d-f-escritura></formulario-carga-p-d-f-escritura>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="4" class=" pl-sm-6">
        <InformacionDocumento :certificado="certificado" v-if="!load.certificado"></InformacionDocumento>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InformacionDocumento from "@/components/SolicitudDocumentos/InformacionDocumento";
import {ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import FormularioCargaPDFEscritura
  from "@/views/index/inscripciones/comercio/FormularioCargaPDFEscritura";

export default {
  name: "AgregarConDiarioOficialView",
  components: {FormularioCargaPDFEscritura, InformacionDocumento},
  data() {
    return {
      title: "Inscripcion de Comercio con Diario Oficial",
      certificado: {
        dias_entrega: 1,
        id_tipo_certificado: 20000, //simular para mostrar el documento representativo
        id_tipo_registro_FK: ID_TIPO_REGISTRO.CONSTITUCION_DE_SOCIEDAD,
        informacionCertificado: {
          informacionCertificado: Object,
          descripcion: "Es una copia simple del título del bien raíz, no señala vigencia. Generalmente es solicitada para estudiar los títulos anteriores previamente a una venta.",
          otras_descripciones: [{
            clave: "Beneficiarios",
            valor: "Persona natural o jurídica, propietaria o no de un inmueble, respecto del cual se desee obtener un documento autorizado por el CBRLS."
          }],
        },
        precio: 5300,
        tipo_certificado: "Inscripción con Diario Oficial ",
        tipo_registro: "Inscripción de Comercio",
        vigencia: -1

      },
      load: {
        tipos_certificados: false,
        certificado: false
      },
      dataFormulario: null
    }
  }
}
</script>

<style scoped>

</style>
