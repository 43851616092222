<template>
  <div>
    <v-row>

      <v-col cols="12" md="8" class="pr-md-0">
        <v-card class="px-4">
          <v-card-title class="pb-1">
            {{ title }}
          </v-card-title>
          <v-card-text v-if="dataFormulario == null">
            <verificacion-escritura-y-extracto-notarios
                @limpiarTodo="limpiarTodo"
                :documento_confirmado="documento_confirmado" ref="refVerificacionEscritura"
                :campos-habilitados="!documento_confirmado"
                @confirmarDocumento="documentoValidado($event)"
                @confirmarDocumentoExtracto=" informcaion_documento_extracto = $event"
                :textoBtnConfirmar="'Sí, es el extracto correcto'"
            >
            </verificacion-escritura-y-extracto-notarios>

          </v-card-text>

          <v-card-text v-if="dataFormulario != null">

            <v-row class="pt-2">

              <v-col :cols="dato.nombre == 'Link' ? 12 : 6" class="py-0" :key="dato.nombre"
                     v-for="dato in getDataFormulario">
                <small class="font-weight-bold">{{ dato.nombre }}</small>
                <v-alert
                    border="left"
                    color="black"
                    dense
                    :icon="false"
                    outlined
                    class="text-center py-1"
                >
                  <div style="color:black; font-size: 13px">
                    {{ dato.valor }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!--        <v-fade-transition>-->
        <!--          <div v-if="documento_validado && dataFormulario == null">-->
        <!--            <panel-confirmar-documento></panel-confirmar-documento>-->
        <!--          </div>-->
        <!--        </v-fade-transition>-->

        <v-fade-transition>
          <div class="mt-3" v-if="documento_confirmado && dataFormulario == null">
            <v-card class="px-4">
              <v-card-title style="word-break: break-word" class="pb-1">
                <span>
                  Publicación en Diario Oficial (opcional)
                <v-icon v-if="linkDiarioOficial == null" title="Se recomienda verificar su documento"
                        color="warning">mdi-check-all
                </v-icon>
                </span>
              </v-card-title>

              <v-card-text>

                <p>Se sugiere acompañar la publicación del <span
                    class="font-weight-bold">extracto de su Escritura</span> indicando el código CVE</p>
                <formulario-carga-p-d-f-escritura
                    @validado="traerDiarioOficial($event)"></formulario-carga-p-d-f-escritura>
              </v-card-text>
            </v-card>

          </div>
        </v-fade-transition>

        <v-fade-transition>
          <div v-if="documento_confirmado">
            <v-card class="mt-3 px-4">
              <v-card-title class="pb-0">Información de Sociedad</v-card-title>
              <v-card-text>
                <v-form name="formCotizar" @submit.prevent="verificarCotizar">
                  <!--                  <span class="info&#45;&#45;text">Importante:</span> Si la presente constitución es producto de una división en-->
                  <!--                  trámite favor indicar el N° de carátula en las observaciones-->

                  <v-row class="">
                    <v-col class="pt-0 pb-2" cols="12" md="6">
                      <v-text-field :disabled="dataFormulario != null"
                                    v-model="sociedad.nombre_sociedad"
                                    hide-details
                                    required
                                    label="Nombre de la Sociedad/empresa"></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-2" cols="12" md="6">
                      <v-text-field :disabled="dataFormulario != null" v-model="sociedad.capital"
                                    type="number"
                                    max="999999999"
                                    min="0" required
                                    hide-details="auto"
                                    label="Capital (en pesos chilenos)"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea :disabled="dataFormulario != null"
                                  v-model="sociedad.observacion" outlined
                                  label="Observaciones"
                                  hide-details="auto"
                                  counter="200" auto-grow clearable
                                  :rules="[rules.length(200)]"></v-textarea>
                      <p class="mb-2" v-if="diarioOficial != null"><span
                          class="font-weight-bold">Nombre de la sociedad: </span>
                        {{ diarioOficial.name }}</p>
                      <p class="mb-2" v-if="diarioOficial != null"><span
                          class="font-weight-bold">Publicación: </span>
                        {{ diarioOficial.url }}</p>
                      <!--                      <v-alert v-if="linkDiarioOficial == null" color="warning" class="text-center mb-3" outlined text>-->
                      <!--                        Acompañar la Publicación en el Diario Oficial permitirá la automatización de algunos procesos-->
                      <!--                        relacionados con la validación de documentos-->
                      <!--                      </v-alert>-->
                      <v-checkbox
                          class="mt-0"
                          hide-details
                          v-model="checkIncluirCopia"
                          :disabled="dataFormulario != null"
                          :label="`Incluir ${copiaAdicional.tipo_certificado}`"
                      ></v-checkbox>
                      <v-checkbox
                          class="mt-0"
                          hide-details
                          v-model="checkIncluirCertificado"
                          :disabled="dataFormulario != null"
                          :label="`Incluir ${otrosDocumentos.certificado_vigencia.tipo_certificado}`"
                      ></v-checkbox>

                      <div class="d-none d-sm-block text-center pt-2">
                        <v-btn color="info" :disabled="!isValido || dataFormulario != null"
                               small outlined
                               type="submit">
                          <v-icon left small>mdi-cart-plus</v-icon>
                          Cotizar trámite
                        </v-btn>
                      </div>
                      <div class="d-block d-sm-none text-center pt-4  ">
                        <v-btn color="info" block
                               :disabled="!isValido || dataFormulario != null" small outlined
                               type="submit">
                          <v-icon left small>mdi-cart-plus</v-icon>
                          Cotizar trámite
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>

              </v-card-text>
            </v-card>
          </div>

        </v-fade-transition>

      </v-col>
      <v-col sm="12" md="4" class=" pl-sm-6">
        <InformacionDocumento :certificado="certificado" v-if="!load.certificado"></InformacionDocumento>
      </v-col>
    </v-row>

    <modal-cotizar-inscripcion-comercio @agregarAlCarro="agregarAlCarroInscripciones" :ver-dialog="modalCotizar"
                                        @ocultarDialog="modalCotizar = false "
                                        :monto-adicional2="checkIncluirCertificado ? 2600 : 0"
                                        :monto-adicional="checkIncluirCopia && copiaAdicional != null ? copiaAdicional.precio : 0"
                                        :monto-cotizado="ultimoMontoCotizado"></modal-cotizar-inscripcion-comercio>
    <modal-documento-agregado :verDialog="modalAgregar.verModalDocumentoAgregado"
                              :documentosAgregados="modalAgregar.lista_agregados"
                              :documentosRepetidos="modalAgregar.documentos_repetidos"
                              :documentosEnLimite="modalAgregar.limite_carro"
                              @ocultarDialog="modalAgregar.verModalDocumentoAgregado = false"></modal-documento-agregado>
    <modal-confirmacion-extracto :ver-dialog="verModalConfirmarExtracto"
                                 @ocultarDialog="verModalConfirmarExtracto = false"
                                 @confirmar="cotizar"></modal-confirmacion-extracto>
  </div>
</template>

<script>
import registrosEntity, {
  ID_AREA_AGRUPACION,
  ID_TIPO_CERTIFICADO,
  ID_TIPO_REGISTRO
} from "@/model/registros/registros.entity";
import InformacionDocumento from "../../../../../components/SolicitudDocumentos/InformacionDocumento";

import carro from "@/model/carro/carro.entity";
import inscripcionesEntity, {DIVISA} from "@/model/inscripciones/inscripciones.entity";
import ModalCotizarInscripcionComercio
  from "@/views/index/inscripciones/comercio/constitucion-sociedad/ModalCotizarInscripcionComercio";
import carroEntity from "@/model/carro/carro.entity";
import ModalDocumentoAgregado from "@/components/ModalDocumentoAgregado";

import FormularioCargaPDFEscritura
  from "@/views/index/inscripciones/comercio/FormularioCargaPDFEscritura";
import ModalConfirmacionExtracto from "@/views/index/inscripciones/components/ModalConfirmacionExtracto";
import VerificacionEscrituraYExtractoNotarios
  from "@/views/index/inscripciones/comercio/constitucion-sociedad/VerificacionEscrituraYExtractoNotarios.vue";

export default {
  name: "AgregarConstitucionSociedadView",
  components: {
    VerificacionEscrituraYExtractoNotarios,
    ModalConfirmacionExtracto,
    FormularioCargaPDFEscritura,

    ModalDocumentoAgregado,
    ModalCotizarInscripcionComercio,

    InformacionDocumento,
  },
  data() {
    return {
      tipos_certificados: [],
      certificado: {
        dias_entrega: 1,
        id_tipo_certificado: 20000, //simular para mostrar el documento representativo
        id_tipo_registro_FK: ID_TIPO_REGISTRO.CONSTITUCION_DE_SOCIEDAD,
        informacionCertificado: {
          informacionCertificado: Object,
          descripcion: "Es una copia simple del título del bien raíz, no señala vigencia. Generalmente es solicitada para estudiar los títulos anteriores previamente a una venta.",
          otras_descripciones: [{
            clave: "Beneficiarios",
            valor: "Persona natural o jurídica, propietaria o no de un inmueble, respecto del cual se desee obtener un documento autorizado por el CBRLS."
          }],
        },
        precio: 5300,
        tipo_certificado: "Constitución de Sociedad ",
        tipo_registro: "Inscripción de Comercio",
        vigencia: -1,

      }, //generar un certificado por defecto solo para la vista previa
      load: {
        tipos_certificados: false,
        certificado: false
      },
      documento_validado: false,
      documento_confirmado: false,
      title: "Constitución de sociedad",
      lista_por_agregar: [],
      informacion_documento: {},
      informcaion_documento_extracto: {},
      dataFormulario: null,
      linkDiarioOficial: null,
      lista_certificados: [],
      sociedad: {
        nombre: "",
        capital: null,
        observacion: "",
        nombre_sociedad: ""
      },
      modalAgregar: {},
      modalCotizar: false,
      ultimoMontoCotizado: null,
      rules: {
        length: len => v => (v || '').length < len || `Porfavor, escriba su observación en un máximo de ${len} caracteres`
      },

      diarioOficial: null,
      formularioPrecargado: false,
      verModalConfirmarExtracto: false,
      copiaAdicional: null,
      checkIncluirCopia: true,
      checkIncluirCertificado: true,
      otrosDocumentos: {certificado_vigencia: {}}
    }
  },
  mounted() {
    this.dataFormulario = carro.getDatosAdicionalesCertificado(ID_TIPO_REGISTRO.CONSTITUCION_DE_SOCIEDAD, ID_AREA_AGRUPACION.CONSTITUCION_DE_SOCIEDAD);

    if (this.dataFormulario) {
      this.formularioPrecargado = true;

      console.log("datos del formulario encontrados: ", this.dataFormulario)

      let obs = this.dataFormulario.find(f => {
        return f.nombre == "Observaciones"
      })

      let soc = this.dataFormulario.find(f => {
        return f.nombre == "Nombre de la sociedad"
      })

      let cap = this.dataFormulario.find(f => {
        return f.nombre == "Capital";
      })

      let lnk = this.dataFormulario.find(f => {
        return f.nombre == "Link";
      })

      this.sociedad.nombre_sociedad = soc.valor;
      console.log("setea aqui el valor: ", soc.valor)

      this.sociedad.capital = parseInt(cap.valor)

      if (lnk) {
        this.linkDiarioOficial = lnk.valor;
      }

      if (obs) {
        this.sociedad.observacion = obs.valor;
        // this.dataFormulario.splice(this.dataFormulario.indexOf(obs), 1);

      }

      // this.dataFormulario.splice(this.dataFormulario.indexOf(soc), 1);
      // this.dataFormulario.splice(this.dataFormulario.indexOf(cap), 1);

    }

    registrosEntity.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO, ID_TIPO_CERTIFICADO.INSCRIPCION_DE_COMERCIO).then(res => {
      this.lista_certificados = [res];
      this.certificado = this.lista_certificados[0];
      registrosEntity.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO, ID_TIPO_CERTIFICADO.COPIA_CON_VIGENCIA).then(res2 => {
        this.lista_certificados.push(res2);
        this.copiaAdicional = res2;
      })
      registrosEntity.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO, ID_TIPO_CERTIFICADO.CERTIFICADO_VIGENCIA_SOCIEDAD).then(res2 => {
        this.otrosDocumentos.certificado_vigencia = res2;
      })


    })
    if (this.dataFormulario) {
      this.documento_validado = true
      this.informacion_documento.notarioSeleccionado = {};
      this.informacion_documento.notarioSeleccionado.nombre = this.dataFormulario.find(f => {
        return f.nombre.toLowerCase() == "notario";
      }).valor

      this.informacion_documento.idDocumento = this.dataFormulario.find(f => {
        return f.nombre.toLowerCase() == "id_documento_electronico";
      }).valor


      this.confirmarDocumento();
    }
  },
  computed: {
    getDataFormulario() {
      return this.dataFormulario.filter(f => {
        return f.nombre.toLowerCase() != "link"
      })
    },
    isValido() {

      if (this.sociedad.observacion.length > 200 || this.sociedad.capital == null || this.sociedad.capital === "" || this.sociedad.capital < 0 || this.sociedad.capital > 999999999 || this.sociedad.nombre_sociedad.length == 0) {
        return false
      }
      return true;
    }
  },
  methods: {
    traerDiarioOficial(diario) {
      this.diarioOficial = diario
      this.sociedad.nombre_sociedad = diario.name
      this.linkDiarioOficial = diario.url
    },
    documentoValidado(documento) {
      this.informacion_documento = documento;
      this.documento_validado = true;
      this.confirmarDocumento();
    },
    confirmarDocumento() {
      this.documento_confirmado = true;
    },
    agregarALista(certificado, certificado2) {

      if (certificado != null) {
        this.validarCertificado(certificado);
      }

      if (certificado2 != null) {
        this.validarCertificado(certificado2);
      }
    },

    validarCertificado(certificado) {
      let aux = this.lista_por_agregar.find(c => {
        return c.id_tipo_certificado === certificado.id_tipo_certificado && c.id_tipo_registro_FK === certificado.id_tipo_registro_FK && c.precio_pagado === certificado.precio_pagado
      })
      if (aux != null) {
        aux.numero_copias = parseInt(aux.numero_copias) + parseInt(certificado.numero_copias);
      } else {
        this.lista_por_agregar.push(certificado);
      }

    },

    eliminarDeLista(cert) {
      this.lista_por_agregar.splice(this.lista_por_agregar.indexOf(cert), 1);
    },
    limpiarTodo() {
      this.documento_validado = false;
      this.documento_confirmado = false;
      this.lista_por_agregar = [];
      this.informacion_documento = {};
      this.$refs.refVerificacionEscritura.refLimpiarDatos();
    },

    verificarCotizar() {
      this.verModalConfirmarExtracto = true;
    },

    cotizar() {
      let certificado = this.lista_certificados[0];
      inscripcionesEntity.getPrecioCalculadoDocumento(ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO, this.linkDiarioOficial == null ? certificado.id_tipo_certificado : ID_TIPO_CERTIFICADO.INSCRIPCION_DE_COMERCIO_CON_NOTA_MARGINAL, this.sociedad.capital, 1).then(res => {
        this.ultimoMontoCotizado = res.obj;
        this.modalCotizar = true;
      })

    },

    agregarAlCarroInscripciones() {
      console.log(this.sociedad.nombre_sociedad)
      console.log("entra a agregar al carro")
      console.log("datos de la sociedad: ", this.sociedad)
      console.log("tiene el nombre: ", this.sociedad.nombre_sociedad)
      console.log("link diario", this.linkDiarioOficial)
      let datos_adicionales = {
        id_documento_electronico: this.informacion_documento.idDocumento,
        link: this.linkDiarioOficial,
        notaria: this.informacion_documento.notarioSeleccionado.nombre,
        nombre_sociedad: this.sociedad.nombre_sociedad,
        capital: this.sociedad.capital,
        observaciones: this.sociedad.observacion,
        id_documento_electronico_extracto: this.informcaion_documento_extracto.idDocumento,
        notaria_extracto: this.informcaion_documento_extracto.notarioSeleccionado.nombre,
      }
      console.log("genera el objeto")

      let certificado = this.lista_certificados[0];
      console.log('datos adicionales', datos_adicionales)
      let item = {
        id_tipo_registro_FK: ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO,
        id_tipo_certificado: this.linkDiarioOficial == null ? ID_TIPO_CERTIFICADO.INSCRIPCION_DE_COMERCIO : ID_TIPO_CERTIFICADO.INSCRIPCION_DE_COMERCIO_CON_NOTA_MARGINAL,
        precio_pagado: this.ultimoMontoCotizado,
        precio_cliente: this.sociedad.capital,
        tipo_registro: certificado.tipo_registro,
        tipo_certificado: this.linkDiarioOficial == null ? certificado.tipo_certificado : "Inscripción de Comercio con Nota Diario Oficial",
        id_divisa: DIVISA.CLP,
        numero_copias: 1,
        id_area_agrupacion: certificado.id_area_agrupacion
      };
      console.log('item', item)
      let listaItems = [item]

      if (this.checkIncluirCopia) {
        let item2 = {
          id_tipo_registro_FK: this.copiaAdicional.id_tipo_registro_FK,
          id_tipo_certificado: this.copiaAdicional.id_tipo_certificado,
          precio_pagado: this.copiaAdicional.precio,
          precio_cliente: this.copiaAdicional.precio,
          tipo_registro: this.copiaAdicional.tipo_registro,
          tipo_certificado: this.copiaAdicional.tipo_certificado,
          id_divisa: DIVISA.CLP,
          numero_copias: 1,
          id_area_agrupacion: this.copiaAdicional.id_area_agrupacion
        }
        listaItems.push(item2);
        console.log('item2', item2)
      }

      if (this.checkIncluirCertificado) {
        listaItems.push({
          ...this.otrosDocumentos.certificado_vigencia,
          id_tipo_registro_FK: this.otrosDocumentos.certificado_vigencia.id_tipo_registro_FK,
          id_tipo_certificado: this.otrosDocumentos.certificado_vigencia.id_tipo_certificado,
          precio_pagado: this.otrosDocumentos.certificado_vigencia.precio,
          precio_cliente: this.otrosDocumentos.certificado_vigencia.precio,
          tipo_registro: this.otrosDocumentos.certificado_vigencia.tipo_registro,
          tipo_certificado: this.otrosDocumentos.certificado_vigencia.tipo_certificado,
          id_divisa: DIVISA.CLP,
          numero_copias: 1,
          id_area_agrupacion: this.otrosDocumentos.certificado_vigencia.id_area_agrupacion
        })
      }
      console.log('lista items', listaItems)
      carroEntity.agregarAlCarroInscripciones(listaItems, datos_adicionales).then(response => {
        this.modalAgregar.verModalDocumentoAgregado = true;
        this.modalAgregar.documentos_repetidos = response.documentos_repetidos;
        this.modalAgregar.limite_carro = response.limite_carro
        this.modalAgregar.lista_agregados = response.lista_agregados;
        this.modalCotizar = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
