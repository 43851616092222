import axios from "axios";
import qs from "qs";

export const getPrecioCalculadoDocumentoApi = (id_tipo_registro, id_tipo_certificado, precio_cliente, id_divisa) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `inscripciones?action=get-precio-calculado-documento`,
            qs.stringify({
                id_tipo_registro: id_tipo_registro,
                id_tipo_certificado: id_tipo_certificado,
                precio_cliente: precio_cliente,
                id_divisa: id_divisa

            })
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        })
    })
}
