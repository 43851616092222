<template>
  <v-dialog max-width="500" v-model="ver" persistent>
    <v-card class="px-4">
      <v-card-title class="pb-0">
        Agregar copias de inscripción
      </v-card-title>
      <v-card-text>
        <p class="">¿Desea agregar una copia autorizada para cada inscripciones cotizada? <a target="_blank"
                                                                                    href="/informacion/faq#faq-16">Más
          informacion</a></p>

        <v-row v-if="!has_copias_en_carro">
          <v-col cols="3">
            <v-btn block color="error" small outlined @click="agregarCertificadoSinCopia">No</v-btn>
          </v-col>
          <v-col cols="9">
            <v-btn block color="success" :disabled="loaded < 3" :loading="loaded < 3" small
                   @click="agregarCertificadoConCopia">Si, agregar copia
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {DIVISA} from "@/model/inscripciones/inscripciones.entity";
import registrosEntity, {ID_TIPO_CERTIFICADO} from "@/model/registros/registros.entity";

export default {
  name: "ModalAgregarCopia",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    certificados: {
      type: Array,
      default() {
        return []
      }
    },
    id_tipo_registro: null
  },
  data() {
    return {
      ver: false,
      divisa: null,
      precio: null,
      ID_DIVISA: DIVISA,
      copia_prohibicion: null,
      copia_hipoteca: null,
      copia_autorizada: null,
      loaded: 0,
      has_copias_en_carro: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      } else {
        this.divisa = null;
        this.precio = null;
      }
    }
  },
  created() {

    this.loaded = 0;

    registrosEntity.getFromTiposRegistrosTiposCertificados(this.id_tipo_registro, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA).then(res => {
      this.copia_autorizada = res;
      this.loaded++;
    })
    registrosEntity.getFromTiposRegistrosTiposCertificados(this.id_tipo_registro, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_HIPOTECA).then(res => {
      this.copia_hipoteca = res;
      this.loaded++;
    })
    registrosEntity.getFromTiposRegistrosTiposCertificados(this.id_tipo_registro, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_PROHIBICION).then(res => {
      this.copia_prohibicion = res;
      this.loaded++;
    })
  },
  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },

    agregarCertificadoSinCopia() {
      this.$emit("agregarCertificado", [])
    },
    agregarCertificadoConCopia() {

      let tipo_certificado_busqueda;
      let copias_por_agregar = [];
      this.certificados.map(certificado => {
        console.log("evaluando certificado: ", certificado)
        switch (certificado.id_tipo_certificado) {
          case ID_TIPO_CERTIFICADO.INSCRIPCION_DE_PROPIEDAD:
            tipo_certificado_busqueda = this.copia_autorizada;
            break;
          case ID_TIPO_CERTIFICADO.INSCRIPCION_DE_HIPOTECA:
            tipo_certificado_busqueda = this.copia_hipoteca;
            break;
          case ID_TIPO_CERTIFICADO.INSCRIPCION_DE_PROHIBICION:
            tipo_certificado_busqueda = this.copia_prohibicion;
            break;
        }

        let copia = {
          id_tipo_registro_FK: tipo_certificado_busqueda.id_tipo_registro_FK,
          id_tipo_certificado: tipo_certificado_busqueda.id_tipo_certificado,
          precio_pagado: tipo_certificado_busqueda.precio,
          precio_cliente: tipo_certificado_busqueda.precio,
          tipo_certificado: tipo_certificado_busqueda.tipo_certificado,
          tipo_registro: tipo_certificado_busqueda.tipo_registro,
          id_divisa: DIVISA.NA,
          numero_copias: certificado.numero_copias,
          id_area_agrupacion: tipo_certificado_busqueda.id_area_agrupacion
        };
        copias_por_agregar.push(copia)

      })

      this.$emit("agregarCertificado", copias_por_agregar);

    }
  }
}
</script>

<style scoped>

</style>
