<template>
    <div class="px-4 py-4">
        <div v-if="mostrarFormulario">
            <div class="d-flex">
                <h3>Formulario 2890 SII</h3>
                <v-menu
                        offset-x
                        open-on-hover
                        class="pa-0 ma-0"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="info"
                                class="ml-2"
                        >mdi-information-outline
                        </v-icon>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item class="ma-0 pa-0">
                            <img
                                    height="280"
                                    width="200"
                                    src="@/assets/img/inscripcion-prp/f2890.jpg"
                                    alt="imagen de demostración para agregar el codigo."
                            />
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <h4 class="pt-2" style="text-align: justify">Si el contrato que requiere inscribir corresponde a un cambio
                de dueño, debe además señalar el
                número de atención del Formulario 2890 del Servicio de Impuestos Internos creado por el notario.</h4>
            <div class="d-flex ">
                <v-text-field
                        placeholder="Ejemplo: 123456789"
                        v-model="numeroF2890"
                        class="pt-4"
                        outlined
                        dense
                        autofocus
                        hide-details
                        maxlength="9"
                ></v-text-field>
                <v-btn
                        class="ml-2"
                        outlined
                        small
                        color="info"
                        elevation="0"
                        style="height: 40px;margin-top: 16px"
                        @click="agregarF2890ArrayList()"
                >
                    <v-icon
                            small
                            class="mr-1"
                    >mdi-file-plus-outline
                    </v-icon>
                    AGREGAR
                </v-btn>
            </div>
            <div v-if="numeroF2890Array.length > 0" >
                <v-chip
                        class="mt-4 mr-2"
                        color="blue"
                        label
                        outlined
                        v-for="(f2890,index) in numeroF2890Array"
                        :key="index"
                >{{ f2890 }}
                        <v-icon
                            style="cursor: pointer"
                            small
                            class="pl-5"
                            @click="eliminarF2890ArrayList(f2890, index)"
                        >mdi-delete</v-icon>
                </v-chip>
            </div>
            <!--<p
                                style="font-size: 12px "
                                class="pa-0 ma-0 pt-2"
                        >¿No sabe si debe adjuntar un F2890? Presione

                            <v-menu
                                    offset-x
                                    class="pa-0 ma-0"
                                    style="width: 200px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                            <span
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="blue&#45;&#45;text mr-1"
                                            >aquí
                                </span>

                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item class="ma-0 pa-2">
                                        <p class="pa-0 ma-0" style="width: 500px">
                                            Aquí se cambia el textoAquí se cambia el textoAquí se cambia el textoAquí se cambia el
                                            textoAquí se cambia el textoAquí se cambia el textoAquí se cambia el textoAquí se cambia
                                            el textoAquí se cambia el textoAquí se cambia el texto
                                        </p>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </p>-->
            <div class="text-center" style=";margin-top: 20px">
                <v-btn
                        class="ml-2"
                        color="orange"
                        outlined
                        small
                        elevation="0"

                        @click="noEnviarNumero()"
                >
                    MI INSCRIPCIÓN NO LO REQUIERE
                </v-btn>
                <v-btn
                        class="ml-2"
                        color="green"
                        outlined
                        small
                        elevation="0"
                        @click="enviarNumero()"
                        :disabled="mostrarValidarFormularios"
                >
                    VALIDAR FORMULARIOS AGREGADOS
                </v-btn>
            </div>
        </div>
        <div v-else>
            <div v-if="conNumero">
                <h3>Formulario 2890 SII: {{ numeroF2890 }}</h3>
            </div>
            <div v-else>
                <h3>
                    <v-icon class="mr-2 " style="margin-bottom: 3px" color="warning" dense>mdi-alert</v-icon>
                    No se ha adjuntado un Formulario 2890 del SII
                </h3>
            </div>
        </div>
    </div>


</template>

<script>
export default {
    name: "AgregarF2890",

    data() {
        return {
            numeroF2890: null,
            numeroF2890Array: [],
            mostrarFormulario: true,
            conNumero: null,
            mostrarValidarFormularios: true,
        }
    },

    watch: {
        numeroF2890Array() {
            if (this.numeroF2890Array.length > 0) {
                this.mostrarValidarFormularios = false;
            }
            if (this.numeroF2890Array.length === 0) {
                this.mostrarValidarFormularios = true;
            }
        },
    },

    methods: {
        eliminarF2890ArrayList(f2890, index){
            console.log("formulario:" + f2890 + ", index:" + index)
            this.numeroF2890Array.splice(index,1);
        },

        agregarF2890ArrayList() {
            if (/^[0-9]{8}[0-9kK]{1}$/.test(this.numeroF2890)) {
                this.numeroF2890Array = this.numeroF2890Array.filter(f2890 => f2890 != this.numeroF2890);
                this.numeroF2890Array.push(this.numeroF2890);
            }

        },

        enviarNumero() {
            this.conNumero = true
            this.mostrarFormulario = false
            let F2890AEnviar = ""
            this.numeroF2890Array.map((f2890, index)=>{
                if(this.numeroF2890Array.length === index +1 ){
                    F2890AEnviar = F2890AEnviar + f2890
                }else{
                    F2890AEnviar = F2890AEnviar + f2890 + ";"
                }
            })
            this.$emit("cambiarEstadoDelSelect", false);
            this.$emit("numeroF2890", F2890AEnviar);
        },
        noEnviarNumero() {
            this.conNumero = false
            this.mostrarFormulario = false
            this.$emit("cambiarEstadoDelSelect", false);
            this.$emit("numeroF2890", 0);
        }
    },
}
</script>

<style scoped>
h3 {
    margin: 0;
    padding: 0;
}

h4 {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

</style>