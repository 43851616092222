<template>
  <v-dialog max-width="500" v-model="ver">
    <v-card>
      <v-card-title>Cotización de inscripción</v-card-title>
      <v-card-text>

        <p class="text-center"> El precio cotizado para su trámite es: </p>
        <p class="text-center">{{ montoCotizado + montoAdicional + montoAdicional2 | clp }}</p>
        <p class="text-center">¿Desea agregar al carro?</p>
        <v-row>
          <v-col cols="12" lg="6" class="text-center">
            <v-btn @click="hideDialog" small outlined block color="grey">
              <v-icon left small >mdi-close</v-icon>
              No, volver a cotizar
            </v-btn>
          </v-col>
          <v-col cols="12" lg="6" class="text-center">
            <v-btn small outlined block color="success" @click="agregarAlCarro">
              <v-icon left small>mdi-cart-plus</v-icon>
              Si, agregar al carro
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalCotizarInscripcionComercio",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    montoAdicional2:{default:0},
    montoCotizado: {default: null},
    montoAdicional : {default: 0}
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    agregarAlCarro(){
      this.$emit("agregarAlCarro");
    }

  }
}
</script>

<style scoped>

</style>
